import { createContext, useState } from "react";
import ordersService from "../services/orders.service";
import stripeService from "../services/stripe.service";
import utilService from "../services/util.service";

export const OrdersContext = createContext();
export const OrdersProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [newOrderCount, setNewOrderCount] = useState(0);

  // Internal
  const [pending, setPending] = useState(false);

  let ordersCtx = {
    loading,
    orders,
    order,
    setOrder,
    newOrderCount,

    list: (
      page = 0,
      per_page = 20,
      status,
      payment_status,
      start_date,
      end_date,
      search_term,
    ) => {
      setLoading(true);
      return ordersService
        .list(
          page,
          per_page,
          status,
          payment_status,
          start_date,
          end_date,
          search_term,
        )
        .then((resp, err) => {
          setLoading(false);
          setOrders(resp.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    },

    get: (order_number) => {
      setLoading(true);
      return ordersService
        .get(order_number)
        .then((resp, err) => {
          setLoading(false);
          setOrder(resp.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    },

    get: (order_number) => {
      setLoading(true);
      return ordersService
        .get(order_number)
        .then((resp, err) => {
          setLoading(false);
          setOrder(resp.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    },

    capture: () => {
      if (!!pending) {
        return Promise.resolve();
      }

      if (order.processor === "stripe") {
        setPending(true);
        return stripeService
          .capture(order)
          .then((resp, err) => {
            setPending(false);
            setOrder(resp.data?.order);
          })
          .catch((err) => {
            setPending(false);
            console.log(err);
            throw "Error capturing payment";
          });
      } else {
        setPending(true);
        return ordersService
          .capture(order)
          .then((resp, err) => {
            setPending(false);
            setOrder(resp.data?.order);
          })
          .catch((err) => {
            setPending(false);
            console.log(err);
            throw "Error capturing payment";
          });
      }
    },

    cancel: (ord) => {
      if (!!pending) {
        return Promise.resolve();
      }

      ord = ord || order;
      console.log(ord);
      if (ord.processor === "stripe") {
        setPending(true);
        return stripeService
          .cancel(ord)
          .then((resp, err) => {
            setPending(false);
            if (!!order) {
              setOrder(resp.data?.order);
            }
          })
          .catch((err) => {
            setPending(false);
            console.log(err);
          });
      } else {
        // setPending(true);
        // return ordersService
        //   .capture(order)
        //   .then((resp, err) => {
        //     setPending(false);
        //     setOrder(resp.data?.order);
        //   })
        //   .catch((err) => {
        //     setPending(false);
        //     console.log(err);
        //   });
      }
    },

    fulfill: (item_id) => {
      if (!!pending) {
        return Promise.resolve(null);
      }

      setPending(true);
      return ordersService
        .fulfill(item_id)
        .then((resp, err) => {
          setPending(false);

          if (!!err) {
            return console.log(err);
          }

          let idx = order.accessories.findIndex((a) => a.id === resp.data?.id);
          if (idx !== -1) {
            order.accessories.splice(idx, 1, resp.data);
            setOrder(JSON.parse(JSON.stringify(order)));
          }
        })
        .catch((err) => {
          setPending(false);
          console.log(err);
        });
    },

    refund: (refund) => {
      if (!!pending) {
        return;
      }

      setPending(true);
      return ordersService
        .refund(refund)
        .then((resp) => {
          setPending(false);
          if (!!resp.data?.order) {
            setOrder(resp?.data?.order);
            return true;
          } else {
            console.log(resp.data);
            return false;
          }
        })
        .catch((err) => {
          setPending(false);
          console.log(err);
          return false;
        });
    },

    toggleFlag: async (flag, item_id, item_type) => {
      const results = await utilService.toggleFlag(flag, item_id, item_type);
      let [toggled, toggledErr] = results.data;

      if (toggledErr !== null) {
        console.log("Error toggling flag");
        return [null, toggledErr];
      } else {
        let order = orders.find((o) => o.id === item_id);
        if (!order) {
          console.log("Couldn't find order");
          return [null, null];
        }

        if (toggled.deleted === true) {
          order.flags = order.flags.filter((f) => f.name !== toggled.name);
        } else {
          order.flags.push(toggled);
        }

        setOrder(order);
      }

      return [toggled, null];
    },

    getNewOrderCount: async () => {
      const results = await ordersService
        .getNewOrderCount()
        .then((resp, err) => {
          if (!!err) {
            return console.log(err);
          }

          console.log(+(resp?.data?.count || 0));

          setNewOrderCount(+(resp?.data?.count || 0));
        })
        .catch((err) => {
          setPending(false);
          console.log(err);
        });
    },
  };

  return (
    <OrdersContext.Provider value={ordersCtx}>
      {children}
    </OrdersContext.Provider>
  );
};
