import { useCallback, useMemo, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UIkit from "uikit";
import { Image } from "uikit-react";
import { InputContainer } from "uikit-react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";

import { AppContexts } from "../../../providers";
import { Form, FormRow, FormInputWrapper, Link } from "../../../ui";
import Tags from "../../common/Tags";
import MasterConfirm from "../../common/Confirm";
import MacrosReservationKeys from "../../common/MacroReservationKeys";
import VisibilityRules from "../../common/VisibilityRules";

const schema = yup.object().shape({
  title: yup.string().required(),
});

const defaultValues = {
  title: "",
  body: "",
  tags: [],
  attachment: "",
  video: "",
  icon: null,
  thumbnail: null,
};

export const MODAL_NAME = "master-property-guide-add";

const PropertyGuideAdd = () => {
  const { user, refresh } = useContext(AppContexts.AuthContext);
  const { uploadFile, genUUID } = useContext(AppContexts.UtilContext);
  const { get, set, settings } = useContext(AppContexts.SettingsContext);
  const { createSection, selectedTab } = useContext(AppContexts.RentalsContext);

  // const [attachments, setAttachments] = useState([]);
  const [attachImage, setAttachImage] = useState({});
  const [attachPDF, setAttachPDF] = useState({});
  const [loading, setLoading] = useState(false);
  const [restrictions, setRestrictions] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRestrictionsChange = (restrictions) => {
    setRestrictions(restrictions);
  }

  // useEffect(() => {
  //   clearErrors();
  //   setAttachImage({});
  //   setAttachPDF({});
  //   if (Object.keys(section).length === 0) {
  //     clearForms();
  //   } else {
  //     Object.keys(defaultValues).forEach((formname) => {
  //       setValue(
  //         formname,
  //         section[formname] ? section[formname] : defaultValues[formname]
  //       );
  //     });
  //     let attachment = guide.attachments?.find(
  //       (attachment) => attachment.type === "pdf"
  //     );
  //     setValue("attachment", attachment?.name);
  //     let video = guide.attachments?.find(
  //       (attachment) => attachment.type === "you_tube"
  //     );
  //     setValue("video", video?.url);
  //     let attachImage = guide.attachments?.find(
  //       (attachment) => attachment.type === "image"
  //     );
  //     if (attachImage) setAttachImage(attachImage);
  //     let attachPDF = guide.attachments?.find(
  //       (attachment) => attachment.type === "pdf"
  //     );
  //     if (attachPDF) setAttachPDF(attachPDF);
  //   }
  // }, [JSON.stringify(guide)]);

  useEffect(() => {
    clearForms();
  }, []);

  const clearForms = useCallback(() => {
    Object.keys(defaultValues).forEach((formname) => {
      setValue(formname, defaultValues[formname]);
    });
    setAttachImage({});
  }, []);

  const handleRegister = async (formdata) => {
    let attachments_buf = [];
    let thumbnail_url;
    let attachment_url;

    if (formdata.thumbnail?.length > 0) {
      thumbnail_url = await uploadFile(
        formdata.thumbnail[0],
        `welcome_guide/attachments/${genUUID()}`,
      );
    }

    if (formdata.attachments?.length > 0) {
      attachment_url = await uploadFile(
        formdata.attachments[0],
        `workorders/attachments/${genUUID()}`,
      );
    }

    if (!!(attachImage?.name || attachImage.original_name)) {
      if (thumbnail_url) {
        attachments_buf.push({ ...attachImage, url: thumbnail_url });
      } else {
        attachments_buf.push(attachImage);
      }
    }

    if (!!(attachPDF?.name || attachPDF?.original_name)) {
      if (attachment_url) {
        attachments_buf.push({ ...attachPDF, url: attachment_url });
      } else {
        attachments_buf.push(attachPDF);
      }
    }

    if (formdata.video) {
      attachments_buf.push({
        type: "you_tube",
        name: "",
        original_name: "",
        url: formdata.video,
      });
    }

    formdata.attachments = attachments_buf;
    formdata.restrictions = restrictions;

    try {
      setLoading(true);
      let [section, sectionErr] = await createSection({ ...formdata });
      if (sectionErr !== null || section === null) {
        UIkit.notification("Failed", "error");
        setLoading(false);
      } else {
        let orders = settings?.welcome_order;
        if (!orders) {
          await get("welcome_order");
          orders = settings["welcome_order"];
        }

        if (!selectedTab) {
          return UIkit.notification("Failed", "error");
        }

        let selectedOrder = orders.filter(
          (o) => o.type === selectedTab.type,
        )[0];
        if (!selectedOrder) {
          return UIkit.notification("Failed", "error");
        }

        selectedOrder.ordering.unshift(+section.welcome_guide_question_id);
        await set("welcome_order", settings.welcome_order);

        UIkit.modal(`#${MODAL_NAME}`).hide();
        UIkit.notification("Added Successfully", "success");

        await Promise.all([
          refresh(),
          get(["guestbook", "welcome_order", "welcome_tabs"]),
        ]);

        setLoading(false);
        clearForms();
      }
    } catch (err) {
      console.log("ERROR", err);
      UIkit.notification("Failed", "error");
      setLoading(false);
    }
  };

  const handleClose = () => {
    clearForms();
    document.body.style.overflow = "unset";
    UIkit.modal(`#${MODAL_NAME}`).hide();
  };

  const deleteImage = () => {
    setValue("thumbnail", null);
    setAttachImage({});
  };

  const deletePDF = () => {
    setValue("attachments", null);
    setAttachPDF({});
  };

  const body = useMemo(() => getValues("body"), [watch("body")]);
  const tags = useMemo(() => getValues("tags"), [watch("tags")]);
  const thumbnail = useMemo(() => getValues("thumbnail"), [watch("thumbnail")]);
  const icon = useMemo(() => getValues("icon"), [watch("icon")]);

  const handleError = (err) => console.error(err);

  return (
    <>
      <div
        id={MODAL_NAME}
        className="uk-modal-full hms-modals uk-modal uk-open"
        uk-offcanvas="flip: true; overlay: true"
      >
        <div className="uk-offcanvas-bar">
          <div className="hms-modals-main">
            <button
              className="uk-offcanvas-close uk-close-large"
              type="button"
              uk-close="true"
            ></button>
            <div className="uk-modal-header">
              <h2 className="uk-modal-title">Add Block</h2>
            </div>
            <div className="uk-modal-body">
              <Form
                className="uk-grid-small"
                data-uk-grid
                onSubmit={handleSubmit(handleRegister, handleError)}
              >
                <FormRow>
                  <FormInputWrapper label="Title*" size="one">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors["title"],
                        })}
                        type="text"
                        {...register("title")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="Tags">
                    <div className="hms-add-tag">
                      <Tags
                        value={tags}
                        onChange={(editedTags) => setValue("tags", editedTags)}
                      />
                    </div>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="Text" size="two">
                    <span className="hms-align-right">
                      <Link className="m-l-10">
                        <a
                          href="https://intercom.help/ruebarueapp/en/articles/9792129-style-shortcuts"
                          target="_blank"
                        >
                          View Styles
                        </a>
                      </Link>
                    </span>
                    <InputContainer>
                      <textarea
                        className={classnames("uk-textarea", {
                          error: errors.body,
                        })}
                        value={body || ""}
                        onChange={(event) =>
                          setValue("body", event.target.value)
                        }
                        rows="7"
                      />
                      <div className="large-msg-typing-btn">
                        <span uk-tooltip="View Macros" className="macro-icon">
                          <img alt="macro-icon" src="/images/macro-icon.svg" />
                        </span>
                        <MacrosReservationKeys />
                      </div>
                      <a href="/ai-assistant"
                        uk-tooltip="AI Assistant"
                        target="_blank"
                        rel="noopener noreferrer" 
                      >
                        <Image src="/images/ai-assistant.svg" />
                      </a>
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <div className="uk-width-1-2@s uk-first-column">
                  <div className="uk-form-label">Photo</div>
                  <div style={{ alignItems: "center" }}>
                    <div className="js-upload uk-placeholder hms-file-uploader uk-text-center hms-file-delete">
                      <div data-uk-form-custom>
                        {!!(attachImage?.name || attachImage?.original_name) ? (
                          <>
                            <input
                              key="thumnail_key"
                              type="file"
                              accept="image/*"
                              {...register("thumbnail")}
                              onChange={(evt) =>
                                setAttachImage({
                                  name: evt.target.files[0]?.name,
                                  original_name: evt.target.files[0]?.name,
                                  type: "image",
                                  url: "",
                                })
                              }
                            />
                            {attachImage?.name || attachImage?.original_name}
                          </>
                        ) : (
                          <>
                            <div>
                              <Image
                                src="/images/upload-image.svg"
                                className="hms-navbar"
                              />
                            </div>
                            <input
                              key="thumnail_key"
                              type="file"
                              accept="image/*"
                              {...register("thumbnail")}
                              onChange={(evt) =>
                                setAttachImage({
                                  name: evt.target.files[0]?.name,
                                  original_name: evt.target.files[0]?.name,
                                  type: "image",
                                  url: "",
                                })
                              }
                            />
                            <span></span>
                          </>
                        )}
                      </div>
                    </div>
                    {!!(attachImage?.name || attachImage?.original_name) && (
                      <MasterConfirm onDelete={deleteImage} />
                    )}
                  </div>
                  <progress
                    id="js-progressbar"
                    className="uk-progress"
                    value="0"
                    max="100"
                    hidden
                  ></progress>
                </div>
                <FormRow>
                  <FormInputWrapper label="Video" size="two">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.video,
                        })}
                        type="text"
                        {...register("video")}
                        placeholder="YouTube or Vimeo Embed Link"
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="PDF Label" size="one">
                    <InputContainer>
                      <input
                        className={classnames("uk-input", {
                          error: errors.label,
                        })}
                        type="text"
                        {...register("attachment")}
                      />
                    </InputContainer>
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <FormInputWrapper label="PDF">
                    <div className="uk-form-custom">
                      {!!(attachPDF.name || attachPDF.original_name) ? (
                        <>
                          <input
                            key="pdf_key"
                            type="file"
                            {...register("attachments")}
                            onChange={(evt) =>
                              setAttachPDF({
                                name: evt.target.files[0]?.name,
                                original_name: evt.target.files[0]?.name,
                                type: "file",
                                url: "",
                              })
                            }
                          />
                          <span className="grey-text">
                            {attachPDF?.name || attachPDF?.original_name}
                          </span>
                        </>
                      ) : (
                        <>
                          <input
                            key="pdf_key"
                            type="file"
                            {...register("attachments")}
                            onChange={(evt) =>
                              setAttachPDF({
                                name: evt.target.files[0]?.name,
                                original_name: evt.target.files[0]?.name,
                                type: "file",
                                url: "",
                              })
                            }
                          />
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <Image
                              src="/images/upload-pdf.svg"
                              className="hms-navbar"
                            />
                          </button>
                        </>
                      )}
                    </div>
                    {!!(attachPDF?.name || attachPDF?.original_name) && (
                      <MasterConfirm onDelete={deletePDF} />
                    )}
                  </FormInputWrapper>
                </FormRow>
                <FormRow>
                  <VisibilityRules
                    restrictions={restrictions}
                    onChangeBlockSettings={handleRestrictionsChange}
                    itemType={'welcome_guide_question'}
                  />
                </FormRow>

                <div>
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <div data-uk-spinner="ratio: 0.5;"></div>
                    ) : (
                      "Save"
                    )}
                  </button>
                  <button
                    className="uk-button hms-btn uk-offcanvas-close"
                    onClick={handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyGuideAdd;
