// Import package components
import React, { useEffect, useContext, useMemo, useState } from "react";
import { Image, Icon } from "uikit-react";
import moment from "moment";
import { AppContexts } from "../../providers";
import { useHistory } from "react-router";
import Loading from "../common/Loading";
import classnames from "classnames";
import AddPlace from "./sections/place";
import AddEvent from "./sections/event";
import AddText from "./sections/text";
import AddUpsell from "./sections/upsell";
import Map from "./map";
import DestinationShare from "./share";
import SortableList from "./common/sortable";

const btnLables = ["place", "text", "event", "upsell"];

const DestinationGuideDetail = (props) => {
  const {
    destination,
    setDestination,
    getSelectedDestination,
    setTabId,
    setDestinationId,
    initSection,
    setIsNew,
    setShared,
    updateDestination,
    updateRecommendationOrder,
  } = useContext(AppContexts.DestinationContext);
  const { images } = useContext(AppContexts.UtilContext);
  const { user } = useContext(AppContexts.AuthContext);

  const [type, setType] = useState("place");
  const [section, setSection] = useState(0);
  const [ordering, setOrdering] = useState([]);
  const [title, setTitle] = useState("");
  const [isEditTitle, setisEditTitle] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [markers, setMarkers] = useState([]);
  const history = useHistory();
  const destinationId = props.match.params.destination_id;

  useEffect(() => {
    document.title = "Area Guide - RueBaRue";
    setDestination({});
    window.scrollTo({ top: 0, behavior: "auto" });
    // get(["destination_tabs"]);
    getSelectedDestination(destinationId);
  }, []);

  const destinationTabs = useMemo(() => {
    let settings = user.settings;
    if (!settings || !settings.destination || !settings.destination_tabs) {
      return [];
    } else {
      return settings.destination.tab_order
        .split(",")
        .map((type) => {
          return settings.destination_tabs.find((tab) => tab.type === type);
        })
        .filter((tab) => !!tab);
    }
  }, [user]);

  useEffect(() => {
    let result = [];
    let placeIds = [];
    setTitle(destination?.name);
    destination?.recommendation_ordering?.forEach((order) => {
      if (order.type?.trim() === destinationTabs?.at(section)?.type?.trim()) {
        order?.ordering?.forEach((val) => {
          destination?.recommendations?.forEach((rec) => {
            if (rec.recommendation_id?.toString() === val?.toString()) {
              let temp = rec;
              if (temp.display_as === "place") {
                placeIds.push(temp.google_place_id);
              } else {
                if (temp.attachments) {
                  let index = temp.attachments.findIndex(
                    (attachment) => attachment.type === "image",
                  );
                  if (index >= 0) {
                    temp.upload_url = alterAssetUrl(
                      temp.attachments[index].url,
                    );
                  } else {
                    temp.upload_url = null;
                  }
                } else {
                  temp.upload_url = null;
                }
              }
              result.push(temp);
            }
          });
        });
      }
    });
    // images(placeIds);
    setRecommendations(result);
    setOrdering(result.map((item) => item.recommendation_id));
  }, [destination, section, destinationTabs]);

  useEffect(() => {
    let m = recommendations
      .filter((r) => {
        return !!+r.lat && !!+r.lng;
      })
      .map((r) => {
        return {
          lat: +r.lat,
          lng: +r.lng,
          text: r.name,
        };
      });

    setMarkers(m);
  }, [recommendations]);

  const handleBack = () => {
    history.push("/areas");
  };

  const handleShare = (destination) => {
    setShared(destination);
  };

  const handleTitleEdit = () => {
    setisEditTitle(true);
  };

  const handleSaveTitle = () => {
    delete destination.recommendations;
    delete destination.recommendation_ordering;
    delete destination.account_id;
    updateDestination({ ...destination, name: title });
    setisEditTitle(false);
  };

  const handleReorder = async (orders) => {
    let or = destination.recommendation_ordering.find(
      (order) => order.type === destinationTabs?.at(section)?.type.trim(),
    );
    if (or) {
      const result = await updateRecommendationOrder({
        ...or,
        ordering: orders,
      });
      if (result.data) {
        let ordering = (destination?.recommendation_ordering || []).map(
          (item) => {
            if (
              +item.recommendation_ordering_id ===
              +result.data.recommendation_ordering_id
            ) {
              return { ...item, ordering: result.data.ordering };
            }
            return item;
          },
        );
        setDestination((prev) => ({
          ...prev,
          recommendation_ordering: ordering,
        }));
      }
    }
  };

  const handleSection = (label) => {
    setType(label);
    const destinationId = props.match.params.destination_id;
    setDestinationId(destinationId);
    const tabId = destinationTabs?.at(section);
    setTabId(tabId);
    initSection();
    setIsNew(true);
  };

  const alterAssetUrl = (url) => {
    return url
      ?.replace("http://uploads.ruebarue.com", "https://public.ruebarue.com")
      ?.replace(
        "s3.us-east-2.amazonaws.com/uploads.ruebarue.com",
        "public.ruebarue.com",
      );
  };

  return (
    <>
      {Object.keys(destination).length === 0 ? (
        <Loading />
      ) : (
        <section>
          <div
            id="hms-page-title"
            className="uk-margin-small-bottom"
            style={{
              position: "sticky",
              top: "45px",
              zIndex: "100",
              background: "white",
            }}
          >
            <div className="uk-flex uk-flex-between uk-flex-middle">
              <div className="uk-flex uk-flex-middle">
                <button
                  className="uk-button hms-img-btn go-back-arrow-btn"
                  onClick={handleBack}
                >
                  <span uk-icon="icon: arrow-left; ratio: 1"></span>
                </button>
                {isEditTitle ? (
                  <div>
                    <input
                      type="text"
                      value={title}
                      style={{ width: "400px" }}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <span onClick={handleSaveTitle}>
                      <Icon options="check" button={true} />
                    </span>
                    <span onClick={() => setisEditTitle(false)}>
                      <Icon options="close" button={true} />
                    </span>
                  </div>
                ) : (
                  <>
                    <h1 className="uk-heading-small">{title}</h1>
                    <button
                      className="uk-button hms-simple-icon-btn"
                      onClick={handleTitleEdit}
                    >
                      <Image src="/images/edit-icon.svg" />
                    </button>
                  </>
                )}
              </div>
              <div className="hms-page-title-btn">
                <button className="uk-button hms-btn hms-blue-btn btn-no-margin">
                  <a
                    href={`https://guide.ruebarue.com/destination/${props.match.params.destination_id}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "white" }}
                  >
                    Guest View
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div id="hms-main-body">
            <div className="hms-property-tabs uk-flex uk-flex-between uk-flex-middle">
              <ul data-uk-tab>
                {destinationTabs?.map((tab, index) => (
                  <li
                    className={classnames("", {
                      "uk-active": index === section,
                    })}
                    key={index}
                  >
                    <a href="#" onClick={() => setSection(index)}>
                      <span>{tab.label}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="uk-flex uk-flex-between uk-flex-top">
              <div className="hms-guest-btn plus-w-2">
                {btnLables.map((label, index) => (
                  <React.Fragment key={`btnLables_${index}`}>
                    {label === "place" ? (
                      <button
                        className={classnames("uk-button hms-btn", {
                          "hms-red-btn": type === label,
                          "hms-white-btn": type !== label,
                        })}
                        onClick={() => handleSection(label)}
                        key={index}
                      >
                        <span uk-icon="icon: plus; ratio: 0.7"></span>
                        &nbsp;{label}
                      </button>
                    ) : (
                      <button
                        className={classnames("uk-button hms-btn", {
                          "hms-red-btn": type === label,
                          "hms-white-btn": type !== label,
                        })}
                        uk-toggle={`target: #destination-${label}-modal`}
                        onClick={() => handleSection(label)}
                        key={index}
                      >
                        <span uk-icon="icon: plus; ratio: 0.7"></span>
                        &nbsp;{label}
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="uk-flex uk-flex-top">
              <div className="uk-width-3-5@s">
                {type === "place" && (
                  <AddPlace
                    destinationId={props.match.params.destination_id}
                    tabId={destinationTabs?.at(section)}
                  />
                )}
                <SortableList
                  destination={destination}
                  items={recommendations}
                  useDragHandle
                  transitionDuration="0"
                  orders={ordering}
                  // onUpdate={handleUpdate}
                  // onDelete={handleDelete}
                  onSortEnd={handleReorder}
                  destinationTabs={destinationTabs}
                  section={section}
                />
                <div
                  className="s-msg-last-updated"
                  style={{ paddingBottom: "30px" }}
                >
                  {"Last Updated: " +
                    moment(destination?.updated_at).format(
                      "MM/DD/YYYY hh:mm a",
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div className="map-wrapper">
        <div className="uk-width-3-5@s"></div>
        <Map
          center={{
            lat: +destination?.lat || 0,
            lng: +destination?.lng || 0,
          }}
          markers={markers}
          showHome={true}
          zoom={11}
        ></Map>
      </div>
      <AddEvent />
      <AddText />
      <AddUpsell />
      <DestinationShare />
    </>
  );
};

export default DestinationGuideDetail;
