import { useEffect, useState } from "react";
import GoogleAutoComplete from "../../common/GoogleAutoComplete"
import classNames from "classnames";

export const StepFirstProperty = ({ value, onChange, error }) => {
  const [placeId, setPlaceId] = useState(null);
  const [isValidate, setValidate] = useState(true);
  const [autoCompleteValue, setAutoCompleteValue] = useState(value.firstUnit || "");

  useEffect(() => {
    console.log(placeId);
    if (autoCompleteValue) {
      if (placeId) {
        setValidate(true);
        onChange('firstUnit', placeId);
      } else {
        onChange('firstUnit', placeId);
        setValidate(false);
      }
    } else {
      onChange('firstUnit', '');
    }
  }, [placeId, autoCompleteValue]);

  return (
      <div className="step active">
        <h2>First Property Address</h2>
        <p>Begin typing and select a suggested address.</p>
        <p className="error">{error.firstUnit?.message || ""}</p>
        {/* <input
          className={"uk-input " + (error.firstUnit ? "error" : "")}
          type="text"
          placeholder="Address"
          value={value.firstUnit}
          onChange={(e) => onChange('firstUnit', e.target.value)}
        /> */}
        <GoogleAutoComplete
          className={classNames({ error: !isValidate || error.firstUnit })}
          width="50%"
          onSelect={(_, value) => setPlaceId(value.text)}
          onChange={(e) => setAutoCompleteValue(e)}
          address={autoCompleteValue}
        />
      </div>
  )
}