export const StepAreaCode = ({ value, onChange, nextStep, error }) => {
    return (
        <div className="step active">
          <h2>Enter Text Line Area Code</h2>
          <p>We support area codes in the US and Canada.</p>
          <div className="uk-width-1-3">
            <input
              className={"uk-input " + (error.primaryAreaCode ? "error" : "")}
              type="text"
              placeholder="Primary area code"
              value={value.primaryAreaCode}
              onChange={(e) => onChange('primaryAreaCode', e.target.value)}
            />
            <p className="error">{error.primaryAreaCode?.message || ""}</p>
            <input
              className="uk-input"
              type="text"
              placeholder="Backup area code (optional)"
              value={value.backupAreaCode}
              onChange={(e) => onChange('backupAreaCode', e.target.value)}
            />
          </div>
        </div>
    )
}