const Lynnbrook = () => {
  return (
    <li className="setting-tab-content uk-active">
      <div className="uk-card hms-left-tabs-content-card mx-w-665">
        <h3>Coming Soon!</h3>

        <p>
          We're excited to be working with Lynnbrook to connect with their
          excellent payment system, but it's still in the works. Please contact{" "}
          <a href="mailto:sales@ruebarue.com">sales@ruebarue.com</a> to stay
          updated
        </p>
      </div>
    </li>
  );
};

export default Lynnbrook;
