import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AppContexts } from "../../providers";

const GettingStarted = () => {
  const history = useHistory();
  const location = useLocation();

  const rentalsContext = useContext(AppContexts.RentalsContext);
  const destinationContext = useContext(AppContexts.DestinationContext);

  useEffect(() => {
    rentalsContext.get();
    destinationContext.get();
  }, []);

  const rental = rentalsContext.rentals[0];
  const destination = destinationContext.listDestinations[0];
  console.log(rentalsContext.rentals, destinationContext.listDestinations)

  return (
    <div className="uk-container onboarding">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">Getting Started</h1>
      </div>
      <div id="hms-main-body">
        <ul className="uk-list uk-margin-small-top">
          <li>
            <div class="uk-grid uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-match" uk-grid>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Home Guide</h4>
                  <p>Add arrival, departure, home, safety and rules for your rental.</p>
                  <button
                    class="uk-button hms-btn hms-no-btn uk-margin-top"
                    disabled={!rental}
                    onClick={() => history.push(`/properties/${rental?.rental_id}`)}>
                    Edit <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Area Guide</h4>
                  <p>Add nearby grocery stores, pharmacies, things to do in your area.</p>
                  <button
                    class="uk-button hms-btn hms-no-btn uk-margin-top"
                    disabled={!destination}
                    onClick={() => history.push(`/areas_detail/${destination?.destination_id}`)}>
                    Edit <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Brand</h4>
                  <p>Upload your logo and add your company colors.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("/settings/guestbook")}>
                    Edit <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>                          
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Preview</h4>
                  <p>Preview the guestbook that includes the home and area guide.</p>
                  <button
                    class="uk-button hms-btn hms-no-btn uk-margin-top"
                    disabled={!rental}
                    onClick={() => window.open(`https://guide.ruebarue.com/rental/${rental?.rental_id}`, '_blank')}>
                    Preview <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
            </div>
          </li>

          <li className="uk-margin-top">
            <p>Customize the layout of your home and area guide.</p>
            <div class="uk-grid uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-match" uk-grid>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Customize Home Guide</h4>
                  <p>Add, remove or regoranize home guide sections.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("/master-home-guide")}>
                    Edit Template <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Customize Area Guide</h4>
                  <p>Add, remove or regoranize area guide sections.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("/settings/destination-guide")}>
                    Manage <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
            {/*
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Manage Home Guides</h4>
                  <p>Add, edit, preview and share home guides.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("/properties")}>
                    Manage <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Manage Area Guides</h4>
                  <p>Add, edit, preview and share area guides.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("/areas")}>
                    Manage <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
            */}
            </div>
          </li>        

          {/*
          <li className="uk-margin-top">
            <h3>Extras & Payments</h3>
            <p class="uk-text-small">Add essential guest info for each rental.</p>

            <div class="uk-grid uk-child-width-1-4@m uk-child-width-1-2@s uk-grid-match" uk-grid>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Extras Guide</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("master-home-guide")}>
                    Edit <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Manage Sections</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("master-home-guide")}>
                    Manage <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
              <div>
                <div class="uk-card uk-card-default uk-card-body onboarding-block">
                  <h4>Payment Processing</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                  <button class="uk-button hms-btn hms-no-btn uk-margin-top" onClick={() => history.push("master-home-guide")}>
                    Configure <span uk-icon="icon: arrow-right"></span>
                  </button>
                </div>
              </div>
            </div>
          </li>        
          */}
        </ul>
      </div>
    </div>
  );
};

export default GettingStarted;