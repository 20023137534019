import { useContext, useEffect, useState } from "react";

import { AppContexts } from "../../../providers";
import { host } from "../../../http.js";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

export const ModifyForm = ({ rentals }) => {
  const {
    accountCustomer,
    setAccountCustomer,
    updateAccountSubscription,
    createSetupIntent,
  } = useContext(AppContexts.StripeContext);

  const stripe = useStripe();
  const elements = useElements();

  const [pending, setPending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPending(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.log("Submit Err:", submitError);
    }

    let [intent, intentErr] = await createSetupIntent();

    const result = await stripe.confirmSetup({
      elements,
      clientSecret: intent.client_secret,
      confirmParams: {
        return_url: `${host}/v2/stripe/complete_payment_modification`,
      },
    });

    setPending(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    paymentMethodOrder: ["card"],
    wallets: "never",
  };

  return (
    <form onSubmit={handleSubmit}>
      <section className="payment-data">
        <PaymentElement />
      </section>

      <section className="submit">
        <button
          className="uk-button uk-button-# hms-btn hms-red-btn"
          disabled={!stripe || !!pending}
        >
          {pending === true && <div data-uk-spinner="ratio: 0.5;"></div>}
          Update
        </button>
      </section>
    </form>
  );
};
