import { useEffect, useMemo, useContext, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { Flex } from "uikit-react";
import classnames from "classnames";
import { AppContexts } from "../../providers";
import Pagination from "../common/Pagination";
import AddPropertyGuide from "./add";
import Share from "./share";
import TableRow from "./row";
import { Link } from "../../ui";
import TagsList from "./tags_list";
import RecordsPerPage from "../common/RecordsPerPage";
import { useCookies } from "react-cookie";
import TableLoading from "../common/Loading/table_loading";
import { useDeepCompareEffect } from "../../hook";

const defaultCriteria = {
  search_term: "",
  sort: "updated_at",
  filter: "destination_id",
  page: 0,
  tag: "all",
};

const parseQueryString = (queryString) => {
  return {
    ...defaultCriteria,
    ...Object.fromEntries(new URLSearchParams(queryString)),
  };
};

const Properties = () => {
  const {
    loading,
    get,
    setIsNew,
    rentals,
    selectedFlags,
    setSelectedFlags,
    selectedRentals,
    setSelectedRentals,
  } = useContext(AppContexts.RentalsContext);
  const settingsContext = useContext(AppContexts.SettingsContext);
  const DestinationContext = useContext(AppContexts.DestinationContext);
  const authContext = useContext(AppContexts.AuthContext);
  const userType = authContext.user?.details?.type;
  const [cookies] = useCookies(["records-per-page"]);
  const history = useHistory();
  const location = useLocation();
  const { "records-per-page": recordsPerPage } = cookies;

  const [criteria, setCriteria] = useState(() => {
    return location.search
      ? parseQueryString(location.search)
      : defaultCriteria;
  });

  useEffect(() => {
    document.title = "Home Guides - RueBaRue";
    get();
    settingsContext.get(["tags"]);
    DestinationContext.get();
  }, []);

  const listGuides = useMemo(() => {
    if (!rentals) {
      return [];
    } else {
      return rentals.map((rental) => {
        let destinationTag = rental?.tags
          ?.filter((item) => item.type === "destination")
          .sort((prev, next) => prev.name.localeCompare(next.name));
        let rentalTag = rental?.tags
          ?.filter((tag) => tag.type !== "destination")
          .sort((prev, next) => prev.name.localeCompare(next.name));
        let filteredRentalTag = rentalTag?.filter((item) => {
          if (!destinationTag.find((tag) => tag.name === item.name)) {
            return true;
          }
          return false;
        });
        filteredRentalTag?.forEach((item) => {
          destinationTag.push(item);
        });
        return { ...rental, tags: destinationTag };
      });
    }
  }, [rentals]);

  const handleSelectAll = useCallback(
    (event) => {
      setSelectedFlags((flags) => flags.map(() => event.target.checked));
    },
    [selectedFlags],
  );

  const filteredGuides = useMemo(
    () =>
      listGuides
        .filter((guide) => {
          if (criteria.tag && criteria.tag != "all") {
            if (
              !guide.tags ||
              guide.tags.reduce(
                (passed, tag) => passed || tag.name === criteria.tag,
                false,
              ) === false
            ) {
              return false;
            }
          }
          if (criteria.search_term) {
            if (
              !guide.pms_id
                .toLowerCase()
                .includes(criteria.search_term.trim()) &&
              !guide.name.toLowerCase().includes(criteria.search_term.trim())
            ) {
              return false;
            }
          }
          if (criteria.filter === "destination_id") {
            return true;
          } else if (criteria.filter === "mis_destination_id") {
            return !Number(guide.destination_id);
          } else {
            return !guide.pms_id;
          }
        })
        .sort((prev, next) => {
          if (criteria.sort === "updated_at")
            return next[criteria.sort].localeCompare(prev[criteria.sort]);
          if (criteria.sort === "name")
            return prev[criteria.sort].localeCompare(next[criteria.sort]);
          if (criteria.sort === "pms_id")
            return prev[criteria.sort].localeCompare(next[criteria.sort]);
        }),
    [listGuides, criteria],
  );

  const pageGuides = useMemo(
    () =>
      filteredGuides.slice(
        recordsPerPage * criteria.page,
        recordsPerPage * (criteria.page + 1),
      ),
    [filteredGuides, recordsPerPage],
  );

  useEffect(() => {
    setSelectedFlags(Array(filteredGuides.length).fill(false));
  }, [filteredGuides.length]);

  const selectedAll = useMemo(
    () => selectedFlags.every((checked) => checked),
    [selectedFlags],
  );

  const selectedAny = useMemo(
    () => selectedFlags.some((checked) => checked),
    [selectedFlags],
  );

  useEffect(() => {
    setSelectedRentals(
      selectedFlags
        .map((selected, index) => (selected ? filteredGuides[index] : null))
        .filter((contact) => contact),
    );
  }, [selectedFlags, filteredGuides]);

  const allTags = useMemo(() => {
    if (!settingsContext.settings) {
      return [];
    } else {
      if (!settingsContext.settings.tags) {
        return [];
      } else {
        return settingsContext.settings.tags.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
      }
    }
  }, [settingsContext.settings]);

  const handleIndexChanged = (newPageIndex) => {
    handleCriteriaChange("page", newPageIndex);
  };
  const handleCriteriaChange = useCallback((key, value) => {
    setCriteria((prev) => ({ ...prev, [key]: value }));
  }, []);

  useDeepCompareEffect(() => {
    const searchParams = new URLSearchParams(criteria).toString();
    history.replace(`/properties?${searchParams}`);
  }, [criteria]);

  return (
    <>
      <section id="property-guide-template">
        <div id="hms-page-title">
          <h1 className="uk-heading-small">Home Guides</h1>
        </div>

        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #addPropertyGuide-modal"
                disabled={userType === 'trial'}
                onClick={() => setIsNew(true)}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> HOME GUIDE
              </button>
              <TagsList all={allTags} disabled={!selectedAny} />
              <button
                className="uk-button hms-btn hms-white-btn"
                onClick={() => history.push("master-home-guide")}
              >
                MASTER HOME GUIDE
              </button>
              <button
                className="uk-button hms-btn hms-white-btn"
                onClick={() => history.push("/reports/macros")}
              >
                MACRO VALUES
              </button>
              <div data-uk-dropdown="mode: click">
                <ul className="uk-nav uk-dropdown-nav tags-selector">
                  {allTags?.map((tagValue, index) => (
                    <li key={index}>
                      <label>
                        <input className="uk-checkbox" type="checkbox" />
                        {tagValue.name}
                      </label>
                    </li>
                  ))}
                </ul>
                <hr />
                <a href="/settings/tags">Manage Tags</a>
              </div>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card">
            <div className="uk-overflow-auto">
              <div>
                <div className="uk-form uk-flex uk-flex-between uk-flex-top guest-form-top-filters">
                  <div className="hms-form">
                    <div className="uk-inline">
                      <div
                        uk-form-custom="target: > * > span:first-child"
                        id="sort"
                      >
                        <select
                          value={criteria.filter}
                          onChange={(e) =>
                            handleCriteriaChange("filter", e.target.value)
                          }
                          className="uk-margin-small-right"
                        >
                          <option value="destination_id">No Filter</option>
                          <option value="mis_destination_id">
                            Missing Area Guide
                          </option>
                          <option value="pms_id">No Property ID</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    {!settingsContext.loading && (
                      <div className="uk-inline">
                        <div uk-form-custom="target: > * > span:first-child">
                          <select
                            value={criteria.tag}
                            onChange={(e) =>
                              handleCriteriaChange(
                                "tag",
                                e.target.value === "all"
                                  ? null
                                  : e.target.value,
                              )
                            }
                          >
                            <option value="all">All Tags</option>
                            {allTags.map((tag, index) => (
                              <option value={tag.name} key={index}>
                                {tag.name}
                              </option>
                            ))}
                          </select>
                          <button
                            className="uk-button uk-button-default"
                            type="button"
                            tabIndex="-1"
                          >
                            <span></span>
                            <span uk-icon="icon: chevron-down"></span>
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        <span
                          className="uk-search-icon-flip"
                          uk-search-icon="true"
                        ></span>
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Property Name or ID"
                          value={criteria.search_term}
                          onChange={(e) =>
                            handleCriteriaChange(
                              "search_term",
                              e.target.value.toLowerCase(),
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="hms-sortby-btn">
                        <button className="uk-button" type="button">
                          SORT BY<span uk-icon="icon: triangle-down"></span>
                        </button>
                        <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                          <ul className="uk-nav uk-dropdown-nav">
                            <li
                              className={classnames({
                                "uk-active": criteria.sort === "updated_at",
                              })}
                            >
                              <Link
                                href="#"
                                onClick={() =>
                                  handleCriteriaChange("sort", "updated_at")
                                }
                              >
                                Last Updated
                              </Link>
                            </li>
                            <li
                              className={classnames({
                                "uk-active": criteria.sort === "name",
                              })}
                            >
                              <Link
                                href="#"
                                onClick={() =>
                                  handleCriteriaChange("sort", "name")
                                }
                              >
                                Name
                              </Link>
                            </li>
                            <li
                              className={classnames({
                                "uk-active": criteria.sort === "pms_id",
                              })}
                            >
                              <Link
                                href="#"
                                onClick={() =>
                                  handleCriteriaChange("sort", "pms_id")
                                }
                              >
                                Property ID
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="select-item">
                      <div className="autosend-checkbox">
                        <label htmlFor="propertyCBAll">
                          <input
                            type="checkbox"
                            id="propertyCBAll"
                            checked={selectedAll}
                            onChange={handleSelectAll}
                          />
                        </label>
                      </div>
                    </th>
                    <th>GUIDE</th>
                    <th className="uk-table-expand">TAGS</th>
                    <th className="actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    TableLoading(5)
                  ) : pageGuides.length > 0 ? (
                    pageGuides.map((guide, index) => (
                      <TableRow
                        guide={guide}
                        index={index}
                        allTags={allTags}
                        key={index}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} style={{ padding: "30px 25px" }}>
                        No home guides matching your current search or filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Flex alignment="between top" className="guest-form-top-filters">
            <Pagination
              count={filteredGuides.length}
              index={Number(criteria.page)}
              onIndexChanged={handleIndexChanged}
            />
            <RecordsPerPage />
          </Flex>
        </div>
      </section>
      <AddPropertyGuide />
      <Share />
    </>
  );
};

export default Properties;
