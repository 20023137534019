export const FinalStep = ({ value }) => {
  const { product, pms, primaryAreaCode, backupAreaCode } = value || {};

  return (
    <div className="step active">
      <h2>Thank you!</h2>
      <p>You're all set! 🎉 Thanks for completing the setup. Below is a quick summary of your selections and what to expect next.</p>

      <p><strong>Product Selected:</strong> {product === "Both" ? "Digital Guestbook & Texting Platform" : product}</p>        
      <p><strong>Property Management System (PMS):</strong> {pms}</p>
      {product === "Texting Platform" && (
        <>
          <p><strong>Primary Area Code:</strong> {primaryAreaCode}</p>
          {backupAreaCode && <p><strong>Backup Area Code:</strong> {backupAreaCode}</p>}
        </>
      )}

      <p><strong>Next Steps:</strong></p>
      <ul>
        {product === "Digital Guestbook" && (
          <>
            <li>Next, you'll be taken to the <strong>Getting Started</strong> page, where you can add, remove, and organize content and preview your guide.</li>
            <li>If you selected a compatible PMS, our support team will provide instructions for integration.</li>
            <li>If you manage multiple properties, we can automatically generate guides after PMS integration. Our support team will contact you with the next steps.</li>
          </>
        )}

        {product === "Texting Platform" && (
          <>
            <li>Our support team will reach out once a text line is assigned to you.</li>
            <li>If you selected a compatible PMS, our support team will provide instructions for integration.</li>
          </>
        )}

        {product === "Both" && (
          <>
            <li>Your first guide has been created!</li>
            <li>Next, you'll be taken to the <strong>Getting Started</strong> page, where you can add, remove, and organize content and preview your guide.</li>
            <li>Our support team will reach out once a text line is assigned to you.</li>
            <li>If you selected a compatible PMS, our support team will provide instructions for integration.</li>
          </>
        )}
      </ul>
    </div>
  );
};