import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Link } from "../../ui-kit/Link";
import { AppContexts } from "../../../providers";

const LoginForm = (props) => {
  const [creds, setCreds] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  let authCtx = useContext(AppContexts.AuthContext);

  useEffect(() => {
    document.title = "Sign In - RueBaRue";
  });

  const update = (key, e) => {
    setCreds({ ...creds, [key]: e.target.value });
    setError(null);
  };

  const login = (e) => {
    e.preventDefault();

    if (!creds.email || !creds.password) {
      setError({
        msg: "Email and Password are required",
        fields: ["email", "password"],
      });
      return;
    }
    setLoading(true);
    authCtx
      .login(creds.email, creds.password)
      .then((user, err) => {
        setLoading(false);
        if (user.details?.type === 'onboarding') {
          window.location.assign("/onboarding");
        } else {
          window.location.assign("/");
        }
      })
      .catch((err) => {
        let { error, fields } = err.response.data;
        setLoading(false);
        setError({ msg: error, fields: fields });
        // if (err === "trial_expired") {
        //   history.push("/expired");
        // }
      });
  };

  console.log(error);
  return (
    <div className="page-box sign-in auth">
      <h2 className="uk-heading-small uk-margin-small-bottom">Sign In</h2>
      <div className="">
        <form className="uk-form-stacked" onSubmit={login}>
          <div
            className={
              "uk-margin " +
              ((error?.fields || []).includes("email") ? "error" : "")
            }
          >
            <label className="uk-form-label">Email</label>
            <div className="uk-form-controls">
              <input
                className="uk-input"
                value={creds.email}
                onInput={(e) => {
                  update("email", e);
                }}
              />
            </div>
          </div>
          <div
            className={
              "uk-margin " +
              ((error?.fields || []).includes("password") ? "error" : "")
            }
          >
            <label className="uk-form-label">Password</label>
            <div className="uk-form-controls">
              <input
                type="password"
                className="uk-input"
                value={creds.password}
                onInput={(e) => {
                  update("password", e);
                }}
              />
            </div>
          </div>

          <div className="uk-flex uk-inline-flex">
            <button className="uk-button uk-button-# hms-btn hms-red-btn">
              {loading ? <span data-uk-spinner="ratio: 0.5;" /> : ""}
              {" "}Login
            </button>

            {!!error && <p className="error">{error.msg}</p>}
          </div>

          <div className="uk-margin">
            Need an account? <Link to="signup">Sign Up</Link>
          </div>
          <div className="uk-margin">
            Having trouble with your password?{" "}
            <Link to="reset">Reset Password</Link>
          </div>
          <div className="uk-margin">
            By logging in, you agree to our{" "}
            <a href="https://www.ruebarue.com/privacy" target="_blank">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://www.ruebarue.com/terms" target="_blank">
              Terms of Use
            </a>
            .
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
