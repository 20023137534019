import { createElement, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Connect from "./connect";
import Lynnbrook from "./lynnbrook";
import Navbar from "./navbar";

const DefaultValue = {
  connect: Connect,
  lynnbrook: Lynnbrook,
};

const Payments = () => {
  const history = useHistory();

  let pathArray = history.location.pathname.split("/");
  let path = pathArray.pop().replace("#", "");
  let el = DefaultValue[path];

  useEffect(() => {
    if (path === "payments") history.push("/payments/connect");
  }, [path]);

  return (
    <section id="hms-settings-template">
      <div id="hms-main-body">
        <div className="uk-width-1-1@s uk-grid" uk-grid>
          <Navbar />
          <div className="uk-width-expand hms-left-tabs-content">
            <ul id="settings-tab-left" className="uk-switcher">
              {!!el && createElement(el, {})}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Payments;
