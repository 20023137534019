import { useContext, useEffect, useState } from "react";

import { AppContexts } from "../../../providers";
import { host } from "../../../http.js";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

export const CheckoutForm = ({ rentals }) => {
  const {
    accountCustomer,
    setAccountCustomer,
    createAccountCustomer,
    createAccountSubscription,
  } = useContext(AppContexts.StripeContext);

  const stripe = useStripe();
  const elements = useElements();

  const [errors, setErrors] = useState([]);
  const [product, setProduct] = useState("guides");
  const [count, setCount] = useState(1);
  const [pending, setPending] = useState(false);
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    setCount(rentals.length);
  }, [rentals]);

  const updateCustomer = (field, value) => {
    setCustomer({ ...customer, [field]: value });
  };

  const updateProduct = (e) => {
    setProduct(e.target.value);
  };

  const updateCount = (e) => {
    setCount(e.target.value);
  };

  const getPrice = (units) => {
    const tiers = {
      all: [
        { min: 0, price: 14.99 },
        { min: 1, price: 8.79, flat: 22.0 },
        { min: 20, price: 6.59, flat: 44.0 },
        { min: 100, price: 6.15, flat: 99.0 },
      ],
      guides: [
        { min: 0, price: 9.99, flat: null },
        { min: 5, price: 5.49, flat: 22.0 },
        { min: 20, price: 4.39, flat: 44.0 },
        { min: 100, price: 3.95, flat: 99.0 },
      ],
      messaging: [
        { min: 0, price: 9.99, flat: null },
        { min: 5, price: 5.49, flat: 22.0 },
        { min: 20, price: 4.39, flat: 44.0 },
        { min: 100, price: 3.95, flat: 99.0 },
      ],
    };

    let pricePoints = tiers[product];
    let prices = pricePoints.filter((p) => units > p.min).map((p) => p.price);

    return "$" + (Math.min(...prices) * units).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPending(true);
    // TODO: Better validation
    let errs = [];
    if (customer.name.trim() === "") {
      errs.push("name");
    }

    if (customer.email.trim() === "") {
      errs.push("email");
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setPending(false);
      console.log("Submit Err:", submitError);
      return setErrors(errs);
    }

    if (errs.length > 0) {
      setPending(false);
      return setErrors(errs);
    }

    let [cust, custErr] = await createAccountCustomer(customer);
    if (custErr !== null) {
      // set notification
      setPending(false);
      console.log("Customer Error:", custErr);
      return;
    }

    let [sub, subErr] = await createAccountSubscription({
      customer_token: cust.customer_token,
      product: "guides",
    });

    if (subErr !== null) {
      // set notification
      setPending(false);
      console.log("Subscription Error:", subErr);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      clientSecret: sub.client_secret,
      confirmParams: {
        return_url: `${host}/v2/stripe/complete_subscription`,
      },
    });

    setPending(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
    paymentMethodOrder: ["card"],
    wallets: "never",
  };

  return (
    <form onSubmit={handleSubmit}>
      {/*
      <section className="products">
        <h3>Select your product</h3>
        <div className="product-selection">
          <div className="rbr-radio-btn">
            <label className={product === "all" ? "active" : ""} for="input-1">
              Guides + Messaging
            </label>
            <input
              id="input-1"
              type="radio"
              name="product"
              value="all"
              onChange={updateProduct}
            />
          </div>
          <div className="rbr-radio-btn">
            <label
              className={product === "guides" ? "active" : ""}
              for="input-2"
            >
              Only Guides
            </label>
            <input
              id="input-2"
              type="radio"
              name="product"
              value="guides"
              onChange={updateProduct}
            />
          </div>
          <div className="rbr-radio-btn">
            <label
              className={product === "messaging" ? "active" : ""}
              for="input-3"
            >
              Only Messaging
            </label>
            <input
              id="input-3"
              type="radio"
              name="product"
              value="messaging"
              onChange={updateProduct}
            />
          </div>
        </div>

        <div className="pricing">
          <h3>Pricing</h3>
          <p>How many units do you have</p>
          <input
            className="uk-range"
            type="range"
            value={count}
            onInput={updateCount}
            min="1"
            max="300"
            step="1"
          />
          <ul className="uk-list">
            <li>
              Due Today ({rentals.length} units):{" "}
              <b>{getPrice(rentals.length)}</b>
            </li>
            {rentals.length !== +count && (
              <li>
                Price with {count} units: <b>{getPrice(count)}</b>
              </li>
            )}
          </ul>
        </div>
      </section>
      */}

      <section className="products">
        <h2>Subscribe</h2>
        <p>
          Product: <b>Digital Guestbooks</b>
          <br />
          Units: <b>{rentals.length}</b>
          <br />
          Due Today: <b>{getPrice(rentals.length)}</b>
          <br />
        </p>
        <p>
          Want to add <b>Texting Platform</b> to your account? Our Texting
          Platform is available for customers in the US and Canada. Email{" "}
          <a href="mailto:support@ruebarue.com">support@ruebarue.com</a> and
          we’ll help you get set up.
        </p>

        <div className="uk-grid-small stripe-form-match" data-uk-grid>
          <div className="uk-form-controls uk-width-1-2@s">
            <label>Company/Billing Name</label>
            <input
              className="uk-input"
              type="text"
              placeholder=""
              value={customer.name}
              onInput={(e) => updateCustomer("name", e.target.value)}
            />
          </div>

          <div className="uk-form-controls uk-width-1-2@s">
            <label>Email</label>
            <input
              className="uk-input"
              type="text"
              placeholder=""
              value={customer.email}
              onInput={(e) => updateCustomer("email", e.target.value)}
            />
          </div>
        </div>
      </section>

      <section className="payment-data">
        <PaymentElement options={paymentElementOptions} />
      </section>

      <section className="submit">
        <button
          className="uk-button uk-button-# hms-btn hms-red-btn"
          disabled={!stripe || !!pending}
        >
          {pending === true && <div data-uk-spinner="ratio: 0.5;"></div>}
          Submit
        </button>
      </section>
    </form>
  );
};
