import { useContext, useEffect } from "react";
import { AppContexts } from "../../../providers";

const AddTextline = ({ feature }) => {
  const { inbox } = useContext(AppContexts.AuthContext);

  return (
    <>
      {!inbox?.phone && (
        <div className="uk-alert">
          <span>
            This feature requires a texting platform subscription. Please
            contact <a href="mailto:sales@ruebarue.com">sales@ruebarue.com</a>{" "}
            to add a text line.
          </span>
        </div>
      )}
    </>
  );
};

export default AddTextline;
