import { useEffect, useState } from "react";

const daysOptions = Array.from({ length: 30 }, (_, i) => i); // 0-29 days

function convertTimeToMinutes(timeStr) {
  if (timeStr) {
    const [time, period] = timeStr.split(' '); // Split time and AM/PM
    let [hours, minutes] = time.split(':').map(Number); // Split hours and minutes
  
    if (period.toUpperCase() === 'PM' && hours !== 12) {
        hours += 12; // Convert PM hours to 24-hour format
    }
    if (period.toUpperCase() === 'AM' && hours === 12) {
        hours = 0; // Convert 12 AM to 0 hours
    }
  
    return hours * 60 + minutes; // Convert to total minutes
  }
  return 0;
}

function convertMinutesToTime(minutes) {
  let hours = Math.floor(minutes / 60);
  let mins = minutes % 60;
  let period = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for AM and 12 to 12 PM

  // Format minutes with leading zero if needed
  let formattedMins = mins.toString().padStart(2, '0');

  return `${hours}:${formattedMins} ${period}`;
}

const VisibilityRules = ({
  restrictions=[],
  onChangeBlockSettings= () => {},
  itemId,
  itemType
}) => {
  // Visibility toggles
  const [visibleAfterEnabled, setVisibleAfterEnabled] = useState(false);
  const [visibleUntilEnabled, setVisibleUntilEnabled] = useState(false);

  // "After" Fields
  const [fromCheckPoint, setFromCheckPoint] = useState("Check-In");
  const [fromBeforeAfter, setFromBeforeAfter] = useState("Before");
  const [fromDays, setFromDays] = useState(0);
  const [fromTime, setFromTime] = useState("9:00 AM");

  // "Until" Fields
  const [toCheckPoint, setToCheckPoint] = useState("Check-Out");
  const [toBeforeAfter, setToBeforeAfter] = useState("Before");
  const [toDays, setToDays] = useState(0);
  const [toTime, setToTime] = useState("9:00 AM");

  useEffect(() => {
    if (restrictions.length) {
      let visibleAfterBR = restrictions.find((br) => br.restriction_type === 'visible_after');
      let visibleUntilBR = restrictions.find((br) => br.restriction_type === 'visible_until');
      if (visibleAfterBR) {
        setVisibleAfterEnabled(true);
        setFromDays(Math.abs(visibleAfterBR.date_offset));
        setFromCheckPoint(visibleAfterBR.relative_point === 'check-in' ? "Check-In" : "Check-Out");
        setFromBeforeAfter((prev) => {
          if (visibleAfterBR.date_offset > 0) return "After"
          if (visibleAfterBR.date_offset < 0) return "Before"
          return prev;
        });
        setFromTime(convertMinutesToTime(visibleAfterBR.time_of_day));
      } else {
        setVisibleAfterEnabled(false);
        setFromCheckPoint('Check-In');
        setFromBeforeAfter('Before');
        setFromDays(0)
        setFromTime('9:00 AM');
      }

      if (visibleUntilBR) {
        setVisibleUntilEnabled(true);
        setToDays(Math.abs(visibleUntilBR.date_offset));
        setToCheckPoint(visibleUntilBR.relative_point === 'check-in' ? "Check-In" : "Check-Out");
        setToBeforeAfter((prev) => {
          if (visibleUntilBR.date_offset > 0) return "After"
          if (visibleUntilBR.date_offset < 0) return "Before"
          return prev;
        });
        setToTime(convertMinutesToTime(visibleUntilBR.time_of_day));
      } else {
        setVisibleUntilEnabled(false);
        setToCheckPoint('Check-Out');
        setToBeforeAfter('Before');
        setToDays(0);
        setToTime('9:00 AM');
      }
    } else {
      setVisibleAfterEnabled(false);
      setFromCheckPoint('Check-In');
      setFromBeforeAfter('Before');
      setFromDays(0)
      setFromTime('9:00 AM');
      setVisibleUntilEnabled(false);
      setToCheckPoint('Check-Out');
      setToBeforeAfter('Before');
      setToDays(0);
      setToTime('9:00 AM');
    }
  }, [restrictions]);

  useEffect(() => {
    let hideSettings = [];
    if (visibleAfterEnabled) {
      hideSettings.push({
        item_id: itemId,
        item_type: itemType,
        restriction_type: 'visible_after',
        relative_point: fromCheckPoint.toLowerCase(),
        date_offset: fromBeforeAfter.toLowerCase() === 'before' ? -fromDays : fromDays,
        time_of_day: convertTimeToMinutes(fromTime || 0)
      });
    }
    if (visibleUntilEnabled) {
      hideSettings.push({
        item_id: itemId,
        item_type: itemType,
        restriction_type: 'visible_until',
        relative_point: toCheckPoint.toLowerCase(),
        date_offset: toBeforeAfter.toLowerCase() === 'before' ? -toDays : toDays,
        time_of_day: convertTimeToMinutes(toTime || 0)
      });
    }
    onChangeBlockSettings(hideSettings);
  }, [
    itemId,
    itemType,
    visibleAfterEnabled,
    visibleUntilEnabled,
    fromCheckPoint,
    fromBeforeAfter,
    fromDays,
    fromTime,
    toCheckPoint,
    toBeforeAfter,
    toDays,
    toTime
  ]);

  // Generate time options in 15-minute intervals
  const generateTimeOptions = () => {
    return [...Array(24 * 4)].map((_, index) => {
      const timeString = new Date(0, 0, 0, Math.floor(index / 4), (index % 4) * 15).toLocaleString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      return (
        <option value={timeString} key={`time_${index}`}>
          {timeString}
        </option>
      );
    });
  };

  return (

    <div className="uk-width-1-1 uk-grid-margin uk-first-column">
      <ul
        uk-accordion="multiple: true"
        className="scheduler-advanced-options"
      >
        <li className="">
          <div className="uk-accordion-title" href>
            Visibility Settings
          </div>
          <div className="uk-accordion-content">
            <div className="uk-margin-small-bottom uk-flex uk-flex-middle">
              <div className="autosend-checkbox uk-form-controls">
                <label className="setting-switch">
                  <input
                    type="checkbox"
                    checked={visibleAfterEnabled}
                    onChange={() => setVisibleAfterEnabled(!visibleAfterEnabled)}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
              <label className="uk-form-label uk-margin-small-left">Visible After</label>
            </div>            {visibleAfterEnabled && (
              <div className="uk-margin-left">
                <div className="uk-grid uk-child-width-1-2@s uk-grid-small" uk-grid="true">                  
                  <div>
                    <div className="uk-margin-small-bottom">
                      <label className="uk-form-label">Days</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select className="uk-select" value={fromDays} onChange={(e) => setFromDays(Number(e.target.value))}>
                          {daysOptions.map((day) => (
                            <option key={day} value={day}>
                              {day} {day === 1 ? "Day" : "Days"}
                            </option>
                          ))}
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{fromDays} Days</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-margin-small-bottom">
                      <label className="uk-form-label">Check-In/Out</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select className="uk-select" value={fromCheckPoint} onChange={(e) => setFromCheckPoint(e.target.value)}>
                          <option value="Check-In">Check-In</option>
                          <option value="Check-Out">Check-Out</option>
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{fromCheckPoint.replace("-", " ")}</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="uk-margin-small-bottom">
                      <label className="uk-form-label">Before/After</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select className="uk-select" value={fromBeforeAfter} onChange={(e) => setFromBeforeAfter(e.target.value)}>
                          <option value="Before">Before</option>
                          <option value="After">After</option>
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{fromBeforeAfter}</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>

                    <div className="uk-margin-bottom">
                      <label className="uk-form-label">At</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select defaultValue="9:00 AM" value={fromTime} onChange={(e) => setFromTime(e.target.value)}>
                          <option value="">Select a Time</option>
                          {generateTimeOptions()}
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{fromTime || "Select a Time"}</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}

            <div className="uk-margin-small-bottom uk-flex uk-flex-middle">
              <div className="autosend-checkbox uk-form-controls">
                <label className="setting-switch">
                  <input
                    type="checkbox"
                    checked={visibleUntilEnabled}
                    onChange={() => setVisibleUntilEnabled(!visibleUntilEnabled)}
                  />
                  <span className="setting-slider setting-round"></span>
                </label>
              </div>
              <label className="uk-form-label uk-margin-small-left">Visible Until</label>
            </div>

            {visibleUntilEnabled && (
              <div className="uk-margin-left">
                <div className="uk-grid uk-child-width-1-2@s uk-grid-small" uk-grid="true">                  
                  <div>
                    <div className="uk-margin-small-bottom">
                      <label className="uk-form-label">Days</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select className="uk-select" value={toDays} onChange={(e) => setToDays(Number(e.target.value))}>
                          {daysOptions.map((day) => (
                            <option key={day} value={day}>
                              {day} {day === 1 ? "Day" : "Days"}
                            </option>
                          ))}
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{toDays} Days</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-margin-small-bottom">
                      <label className="uk-form-label">Check-In/Out</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select className="uk-select" value={toCheckPoint} onChange={(e) => setToCheckPoint(e.target.value)}>
                          <option value="Check-In">Check-In</option>
                          <option value="Check-Out">Check-Out</option>
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{toCheckPoint.replace("-", " ")}</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="uk-margin-small-bottom">
                      <label className="uk-form-label">Before/After</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select className="uk-select" value={toBeforeAfter} onChange={(e) => setToBeforeAfter(e.target.value)}>
                          <option value="Before">Before</option>
                          <option value="After">After</option>
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{toBeforeAfter}</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-margin-small-bottom">
                      <label className="uk-form-label">At</label>
                      <div uk-form-custom="target: > * > span:first-child">
                        <select value={toTime} onChange={(e) => setToTime(e.target.value)}>
                          <option value="">Select a Time</option>
                          {generateTimeOptions()}
                        </select>
                        <button className="uk-button uk-button-default" type="button" tabIndex="-1">
                          <span>{toTime || "Select a Time"}</span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            )}
          </div>
        </li>
      </ul>    
    </div>
  );
};

export default VisibilityRules;