import { httpV2 } from "../http.js";

const SurveysService = {
  getSurveyList: () => httpV2.get("/surveys/all"),
  getSurveys: (page) => httpV2.get(`/surveys?page=${page}`),
  getSurveyById: (surveyId) => httpV2.get(`/surveys/${surveyId}`),
  getPublicSurvey: (surveyId) => httpV2.get(`/surveys/${surveyId}/public`),
  insertSurvey: (objSurvey) => httpV2.post("/surveys", objSurvey),
  cloneSurvey: (surveyId) => httpV2.get(`surveys/${surveyId}/clone`),
  upsertQuestion: (objQuestion) =>
    httpV2.put(`/surveys/${objQuestion.survey_id}/question`, objQuestion),
  updateSurvey: (objSurvey) =>
    httpV2.put(`/surveys/${objSurvey.survey_id}`, objSurvey),
  getSurveyAndResult: (surveyId, start_date, end_date) =>
    httpV2.get(
      `/surveys/${surveyId}?start_date=${start_date}&end_date=${end_date}`,
    ),
  flagSurveyResult: (surveyResultId) => httpV2.get(`surveys/results/${surveyResultId}/flag`)
};

export default SurveysService;
