import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { Image } from "uikit-react";

import moment from "moment";

import { host } from "../../../http";
import { AppContexts } from "../../../providers";

import DragHandle from "../common/handle";
import RecommendationDelete from "../common/delete";
import Markdown from "../../common/Markdown";
import { Link } from "../../../ui";

const Recommendation = ({ rec, orderNo, tabId, destination }) => {
  const {
    setDeletedRecommendation,
    updateRecommendation,
    setIsNew,
    setDestinationId,
    setTabId,
    setSection,
  } = useContext(AppContexts.DestinationContext);
  // const { imageUrls } = useContext(AppContexts.UtilContext);

  const [isExtendableTip, setIsExtendalbleTip] = useState(false);
  const [tip, setTip] = useState(rec?.tip || "");

  const isPlace = useMemo(() => rec?.display_as === "place", [rec]);

  const handleDelete = () => {
    setDeletedRecommendation(rec);
  };

  const handleSaveTip = () => {
    updateRecommendation({ ...rec, tip: tip, google: null });
    setIsExtendalbleTip(false);
  };

  const handleEdit = () => {
    setIsNew(false);
    setDestinationId(rec.destination_id);
    setTabId(tabId);
    setSection(rec);
  };

  const getAttachment = (type) => {
    return (rec?.attachments || []).filter((a) => a.type === type)[0] || null;
  };

  const hasLoc = () => {
    return !!rec.lat && !!rec.lng;
  };

  const buildVideo = () => {
    let youtubeRegex =
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
      vimeoRegex =
        /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:\/(\w+))?/,
      loomRegex =
        /^https:\/\/(www\.)?loom\.com\/share\/([\w\d]+)(\?sid=[\w\d-]+)?$/;

    var youtubeMatch = (video.url || "").match(youtubeRegex),
      vimeoMatch = (video.url || "").match(vimeoRegex),
      loomMatch = (video.url || "").match(loomRegex);

    if (!!youtubeMatch && youtubeMatch[5].length == 11) {
      let videoId = youtubeMatch[5];

      return `//www.youtube.com/embed/${videoId}?rel=0&modestbranding=1`;
    } else if (!!vimeoMatch) {
      var videoId = vimeoMatch[4];

      var hash = vimeoMatch[5];

      var iframeSrc = `https://player.vimeo.com/video/${videoId}`;
      if (hash) {
        iframeSrc += `?h=${hash}`;
      }
      return iframeSrc;
    } else if (!!loomMatch && loomMatch[2]) {
      var videoId = loomMatch[2];
      var hash = loomMatch[2];

      var iframeSrc = `https://www.loom.com/embed/${videoId}`;
      if (hash) {
        iframeSrc += `?h=${hash}`;
      }
      return iframeSrc;
    } else {
      return null;
    }
  };

  const alterAssetUrl = (url) => {
    return url
      ?.replace("http://uploads.ruebarue.com", "https://public.ruebarue.com")
      ?.replace(
        "s3.us-east-2.amazonaws.com/uploads.ruebarue.com",
        "public.ruebarue.com",
      );
  };

  // const urlImage = useMemo(() => {
  //   let url = isPlace
  //     ? imageUrls.find((img) => img?.google_place_id === rec?.google_place_id)
  //         ?.url
  //     : alterAssetUrl(getAttachment("image")?.url);

  //   if (url) {
  //     return (
  //       <div className={"property-img-box"}>
  //         <img className="destination-img" src={url} alt="destination-img" />
  //         {hasLoc() && <div className="hms-property-number">{orderNo + 1}</div>}
  //       </div>
  //     );
  //   }
  //   return null;
  // }, [rec, imageUrls]);

  const urlImage = () => {
    let host = {
      localhost: "http://localhost:4000",
      "staging.ruebarue.com": "https://staging.api.ruebarue.com/dev",
      "app.ruebarue.com": "https://api.staging.ruebarue.com/beta",
    }[window.location.hostname];

    let url = isPlace
      ? `${host}/api/v2/google/image/${rec?.google_place_id}`
      : alterAssetUrl(getAttachment("image")?.url);

    if (url) {
      return (
        <div className={"property-img-box"}>
          <img className="destination-img" src={url} alt="destination-img" />
          {hasLoc() && <div className="hms-property-number">{orderNo + 1}</div>}
        </div>
      );
    }

    return null;
  };

  const buildEventTimes = () => {
    let { display_as, start_date, start_time, end_date, end_time } = rec;

    if (display_as !== "event") {
      return null;
    }

    let startDate = !!start_date
      ? moment(start_date, "YYYY-MM-DD").format("MMM D, YYYY")
      : "";
    let startTime = !!start_time
      ? moment(start_time, "HH:mm").format("h:mm a")
      : "";
    let endDate = !!end_date
      ? moment(end_date, "YYYY-MM-DD").format("MMM D, YYYY")
      : "";
    let endTime = !!end_time ? moment(end_time, "HH:mm").format("h:mm a") : "";

    let startDisplay = [startDate, startTime].filter(Boolean).join(", ");
    let endDisplay = [endDate, endTime].filter(Boolean).join(", ");

    if (startDisplay && endDisplay) {
      return `${startDisplay} - ${endDisplay}`;
    }

    return startDisplay || endDisplay || null;
  };

  let video = getAttachment("you_tube");
  let file = getAttachment("file");

  const handleCancel = () => {
    setIsExtendalbleTip(false);
    setTip(rec?.tip || "");
  };

  const buildRating = (level) => {
    var stars = "";
    for (var i = 1; i <= level; i += 1) {
      stars += "<span class='icon icon-star-full'></span>";
    }
    if (level % 1 != 0) {
      stars += "<span class='icon icon-star-half'></span>";
      i++;
    }
    for (; i <= 5; i++) {
      stars += "<span class='icon icon-star-empty'></span>";
    }
    return stars;
  };

  const buildPrice = (level) => {
    var price = "";
    for (var i = 1; i <= level; i += 1) {
      price += "$";
    }

    return price;
  };

  const calcCrowDistance = (coords1, coords2) => {
    function toRad(value) {
      return (value * Math.PI) / 180;
    }

    var R = 6371;
    var dLat = toRad(coords2.lat - coords1.lat);
    var dLng = toRad(coords2.lng - coords1.lng);
    var lat1 = toRad(coords1.lat);
    var lat2 = toRad(coords2.lat);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round((d / 1.6) * 10) / 10;
  };

  const buildDirections = (address, place_id) => {
    return `https://www.google.com/maps/search/?api=1&query=${address}&query_place_id=${place_id}`;
  };

  return (
    <li
      className="drag-item"
      data-id={rec.recommendation_id}
      key={rec.destination_id}
    >
      <div
        className="uk-card uk-card-small uk-card-default hms-property-card"
        style={{ marginTop: "5px" }}
      >
        <div className="uk-flex uk-flex-between">
          <div className="hms-property-card-body">
            <div className="uk-flex">
              {urlImage()}
              <div className="hms-property-card-content">
                {buildEventTimes()}
                <h3>{rec?.name || ""}</h3>
                {rec?.google_place_id && rec?.google && (
                  <>
                    <div className="phone-address">
                      {rec.google.address || ""}
                    </div>
                    <div className="phone-place-meta">
                      <ul className="place-meta">
                        <li>
                          <a
                            href={buildDirections(
                              rec.address,
                              rec.google.google_place_id,
                            )}
                            target="_blank"
                          >
                            {calcCrowDistance(destination, rec)} mi
                          </a>
                        </li>
                        {!!rec.google.price && (
                          <li className="place-price">
                            <span>{buildPrice(rec.google.price)}</span>
                          </li>
                        )}
                        {!!rec.google.formatted_phone && (
                          <li>
                            <a
                              href={`tel: ${rec.google.formatted_phone}`}
                              target="_blank"
                            >
                              {rec.google.phone}
                            </a>
                          </li>
                        )}
                        {!!rec.google.rating && (
                          <li>
                            <img
                              className="uk-transition-scale-up uk-transition-opaque google-logo"
                              src="/images/google_logo.png"
                              alt="Google Logo"
                            />
                            <span className="rating">
                              {parseFloat(rec.google.rating).toFixed(1)}
                            </span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: buildRating(rec.google.rating),
                              }}
                            />
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
            {isExtendableTip ? (
              <div className="uk-margin-small-top">
                <textarea
                  rows={5}
                  onChange={(e) => setTip(e.target.value)}
                  value={tip}
                  style={{ width: "100%" }}
                />
                <div className="uk-width-1-1 uk-margin-small-top">
                  <button
                    className="uk-button hms-btn hms-red-btn"
                    type="submit"
                    onClick={handleSaveTip}
                  >
                    Save
                  </button>
                  <input
                    type="button"
                    className="uk-button hms-btn uk-modal-close"
                    value="Cancel"
                    onClick={handleCancel}
                  />
                </div>
              </div>
            ) : (
              <div>
                {tip === "" ? (
                  <div style={{ fontStyle: "italic" }}>
                    Add a tip for your guests
                  </div>
                ) : (
                  <Markdown input={tip} />
                )}
                {rec.display_as === "place" && (
                  <div className="uk-width-1-1 uk-margin-small-top">
                    <button
                      className="uk-button hms-btn hms-red-btn"
                      type="submit"
                      onClick={() => setIsExtendalbleTip(true)}
                    >
                      {tip === "" ? "Add Tip" : "Edit tip"}
                    </button>
                  </div>
                )}
              </div>
            )}

            {!!rec.external_link && (
              <div className="hsm-external-link uk-margin-top">
                <a href={rec.external_link} target="_blank" rel="noreferrer">
                  {rec.external_link}
                </a>
              </div>
            )}

            {!!file && (
              <div className="hsm-pdf uk-margin-top">
                <Image
                  src="/images/pdf-icon.svg"
                  className="uk-margin-small-right"
                />
                <Link href={alterAssetUrl(file.url)} target="_blank">
                  {file.name || file.original_name}
                </Link>
              </div>
            )}

            {!!video && buildVideo()}
          </div>
          <div className="hms-property-card-btn">
            {rec.display_as !== "place" && (
              <>
                <div>
                  <button
                    className="uk-button"
                    uk-toggle={`target: #destination-${
                      rec.display_as === "overview" ? "text" : rec.display_as
                    }-modal`}
                    onClick={() => {
                      handleEdit();
                    }}
                  >
                    <Image src="/images/edit-icon.svg" />
                  </button>
                </div>
                <hr />
              </>
            )}
            <DragHandle />
            <hr />
            <div>
              <button
                className="uk-button"
                uk-toggle="target: #recommendation-delete"
                onClick={() => {
                  handleDelete();
                }}
              >
                <Image src="/images/remove.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <RecommendationDelete />
    </li>
  );
};

export default React.memo(Recommendation);
