import { useState, useContext, useEffect, useMemo } from "react";
import { AppContexts } from "../../providers";
import { StepProduct } from "./steps/product";
import { StepUnitsManaged } from "./steps/unitsManaged";
import { StepPMS } from "./steps/pms";
import { StepAreaCode } from "./steps/areaCode";
import { StepFirstProperty } from "./steps/firstProperty";
import { FinalStep } from "./steps/finalStep";

const 
Onboarding = () => {
  const { onboarding_complete } = useContext(AppContexts.AuthContext);
  const UtilContext = useContext(AppContexts.UtilContext);
  const DestinationContext = useContext(AppContexts.DestinationContext);

  const [currentStep, setCurrentStep] = useState(0);

  const [data, setData] = useState({
    product: "",
    units: 1,
    pms: "",
    primaryAreaCode: "",
    backupAreaCode: "",
    firstUnit: "",
  });
  const [error, setError] = useState({});

  const nextStep = async (e) => {
    switch (currentStep) {
      case 0:
        if (!data.product) {
          setError({ product: { message: 'Please select a product.' } });
        } else {
          setCurrentStep(prev => prev + 1);
        }
        break;
      case 1:
        if (!data.pms) {
          setError({
            pms: { message: 'Please select a property management system you use.' }
          });
        } else {
          setCurrentStep(prev => prev + 1);
        }
        break;
      case 2:
        if (data.product !== 'Digital Guestbook') {
          if (!data.primaryAreaCode) {
            setError({
              primaryAreaCode: { message: 'Please input your phone area code.' }
            });
          } else {
            setCurrentStep(prev => prev + 1);
          }
        } else {
          if (!data.firstUnit) {
            setError({
              firstUnit: { message: 'Please input your property address.' }
            });
          } else {
            await goToGettingStarted();
            setCurrentStep(prev => prev + 1);
          }
        }
        break;
      case 3:
        if (data.product !== 'Digital Guestbook') {
          if (!data.firstUnit) {
            setError({
              firstUnit: { message: 'Please input your property address.' }
            });
          } else {
            await goToGettingStarted();
            setCurrentStep(prev => prev + 1);
          }
        } else {
          window.location.href = "/getting-started";
        }
        break;
      case 4:
        if (data.product === "Texting Platform") {
          window.location.href = "/";
        } else {
          window.location.href = "/getting-started";
        }
        break;
      default:
        break;
    }
  };
  const prevStep = () => setCurrentStep((prev) => prev - 1);
  
  useEffect(() => {
    setError({});
    console.log(error);
  }, [currentStep]);

  const handleChange = (field, value) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  }

  const steps = [
    <StepProduct value={data} onChange={handleChange} error={error} />,
    // <StepUnitsManaged value={data} onChange={handleChange} error={error} />,
    <StepPMS value={data} onChange={handleChange} error={error} />,
    data.product !== 'Digital Guestbook' && (<StepAreaCode value={data} onChange={handleChange} error={error} />),
    <StepFirstProperty value={data} onChange={handleChange} error={error} />,
    <FinalStep value={data}  />
  ].filter(i => i);

  const renderStep = () => {
    return steps[currentStep];
  }

  const goToGettingStarted = async () => {
    console.log(data);
    let rpms = data.pms === 'LiveRez Legacy' ? 'LiveRez' : data.pms === 'LiveRez Ignite' ? 'LMPM' : data.pms;
    try {
      let place = await UtilContext.place(data.firstUnit);
      let newDestination = {
        google_place_id: place.place_id,
        address: place.formatted_address,
        name: place.name + " Area Guide",
        lat: place.geometry.location.lat,
        lng: place.geometry.location.lng,
      };
      let createdDestination =
        await DestinationContext.createDestination(newDestination);
      await onboarding_complete({
        product: data.product,
        units: data.units,
        pms: rpms,
        destination_id: createdDestination.destination_id,
        firstPropertyAddress: createdDestination.address,
        primaryAreaCode: data.primaryAreaCode,
        backupAreaCode: data.backupAreaCode,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = "Onboarding - RueBaRue";
  }, []);

  return (
    <div className="uk-container uk-margin-large-top onboarding">
      {renderStep()}
      <div>
        {(currentStep !== 0 && steps.length - 1 !== currentStep) && <button className="uk-button hms-btn hms-white-btn uk-margin-top" onClick={prevStep}>
          Back
        </button>}
        <button type="button" className="uk-button hms-btn hms-red-btn uk-margin-top" onClick={nextStep}>
          {steps.length - 1 === currentStep ? "Get Started" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default Onboarding;