export const StepProduct = ({ value, onChange, nextStep, error }) => {
    return (
        <div className="step active">
          <h2>Choose Your RueBaRue Product</h2>
          <p>Select the RueBaRue product that best fits your needs. You can update your selection anytime.</p>
          <p className="error">{error.product?.message || ""}</p>
          <div className="uk-child-width-1-3@m" uk-grid="">
            {["Digital Guestbook", "Texting Platform", "Both"].map((product) => (
              <div key={product}>
                <div
                  className={`uk-card uk-card-default uk-card-body uk-text-center uk-card-hover ruebarue-product ${
                    value.product === product ? "selected" : ""
                  }`}
                  onClick={() => onChange('product', product)}
                >
                  <h3>{product}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
    )
}