const pmsList = [
    { name: "Barefoot", logo: "/images/integration-logos/barefoot-logo.png" },
    { name: "Escapia", logo: "/images/integration-logos/escapia-logo.png" },
    { name: "Guesty", logo: "/images/integration-logos/guesty-logo.png" },
    { name: "Hostaway", logo: "/images/integration-logos/hostaway-logo.png" },
    { name: "Hospitable", logo: "/images/integration-logos/hospitable-logo.png" },
    { name: "LiveRez", logo: "/images/integration-logos/liverez-logo.png" },
    { name: "Ignite", logo: "/images/integration-logos/liverez-ignite-logo.png" },
    { name: "LMPM", logo: "/images/integration-logos/lmpm-logo.png" },
    { name: "OwnerRez", logo: "/images/integration-logos/ownerrez-logo.png" },
    { name: "RTR", logo: "/images/integration-logos/rtr-logo.png" },
    { name: "RNS", logo: "/images/integration-logos/rns-logo.png" },
    { name: "Streamline", logo: "/images/integration-logos/streamline-logo.png" },
    { name: "Track", logo: "/images/integration-logos/track-logo.png" },
    { name: "Other", logo: "/images/integration-logos/other-logo.png" },
    { name: "None", logo: "/images/integration-logos/none-logo.png" },
];

export const StepPMS = ({ value, onChange, error }) => {
    return (
        <div className="step active">
          <h2>Choose Your Property Management System (PMS)</h2>
          <p>Select the property management system you use to sync your bookings.</p>
          <p className="error">{error.pms?.message || ""}</p>
          <div className="uk-child-width-1-5@m uk-grid-small pms-list" uk-grid="">
            {pmsList.map(({ name, logo }) => (
              <div key={name}>
                <div
                  className={`uk-card uk-card-default uk-card-body uk-text-center uk-card-hover pms uk-flex uk-flex-center uk-flex-middle ${
                    value.pms === name ? "selected" : ""
                  }`}
                  onClick={() => onChange('pms', name)}
                >
                  <img src={logo} alt={name} className="pms-logo" />
                </div>
              </div>
            ))}          
          </div>
        </div>
    )
}