import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { withRouter } from "react-router";
import UIkit from "uikit";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
// Import package components
import {
  Button,
  Card,
  Flex,
  Icon,
  Image,
  Select,
  SelectOption,
} from "uikit-react";

// import customized components
import SchedulerAdd from "./scheduler_add";
import SchedulerDelete from "./scheduler_delete";

// import context provider
import { AppContexts } from "../../providers";

// import react cookies

// Import base components
import { Link } from "../../ui";

// Import common packages
import MainBody from "../common/MainBody";
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import TableLoading from "../common/Loading/table_loading";
import classnames from "classnames";
import AddTextLine from "../common/AddTextLine";
import SchedulerShare from "./scheduler_share/index.js";
import { useDeepCompareEffect } from "../../hook.js";

const defaultCriteria = {
  type: "All",
  active: "All",
  search: "",
  sort: "updated_at",
  page: 0,
  tag: -1,
};

const parseQueryString = (queryString) => {
  return {
    ...defaultCriteria,
    ...Object.fromEntries(new URLSearchParams(queryString)),
  };
};

const Scheduler = () => {
  // Use Context
  const contextScheduler = useContext(AppContexts.SchedulerContext);
  const settingsContext = useContext(AppContexts.SettingsContext);
  const rentalsContext = useContext(AppContexts.RentalsContext);
  // let { templatesCtx } = useContext(AppContexts.TemplatesContext);
  const { user } = useContext(AppContexts.AuthContext);

  const [cookies] = useCookies(["tagsColumnWidth", "records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const location = useLocation();
  const history = useHistory();
  const [criteria, setCriteria] = useState(() => {
    return location.search
      ? parseQueryString(location.search)
      : defaultCriteria;
  });
  const [activeStates, setActiveStates] = useState([]);

  const updateQueryParams = (newCriteria) => {
    const searchParams = new URLSearchParams(newCriteria).toString();
    history.replace(`/scheduler?${searchParams}`);
  };

  useEffect(() => {
    contextScheduler.selectScheduledMessageList();
    contextScheduler.getSources();
    // eslint-disable-next-line
    UIkit.modal("#scheduler-add").$el.addEventListener("beforehide", () =>
      contextScheduler.setIsEditing(false),
    );
    settingsContext.get(["tags", "branding"]);
    rentalsContext.get();
    document.title = "Scheduler - RueBaRue";
  }, []);

  const tagValues = useMemo(
    () =>
      settingsContext.settings.tags?.sort((a, b) =>
        a.name.localeCompare(b.name),
      ) || [],
    [settingsContext.settings],
  );

  useDeepCompareEffect(() => {
    updateQueryParams(criteria);
  }, [criteria]);

  const handleInputChange = (key, value) => {
    setCriteria({ ...criteria, [key]: value });
  };

  const handleClickNew = () => {
    contextScheduler.setIsNew(true);
    contextScheduler.initMessage();
  };

  const handleClickEdit = (message) => {
    contextScheduler.setIsNew(false);
    contextScheduler.setMessage(message);
  };

  const handleShare = (message) => {
    contextScheduler.setMessage(message);
  };

  const handleClickDelete = (message) => contextScheduler.setMessage(message);

  const handleMessageClone = (objMessage) => {
    const { id, host_id, created_at, updated_at, ...objCloneMessage } =
      objMessage;
    objCloneMessage.name += " - Cloned";
    contextScheduler.insertScheduledMessage(objCloneMessage).then((message) => {
      contextScheduler.setMessage(message);
      UIkit.modal("#scheduler-add").hide();
    });
  };

  const handleIndexChanged = useCallback((newPageIndex) => {
    handleInputChange("page", newPageIndex);
  });

  /**
   * Save Tags Adjusted Width to cookies
   * @param {int} width
   */

  const filteredMessages = useMemo(() => {
    if (contextScheduler.listMessages == null) {
      return [];
    } else {
      let filteredMessages = contextScheduler.listMessages
        .filter((indexMessage) => {
          if (criteria.type === "SMS" && indexMessage.sms === false)
            return false;
          if (criteria.type === "Email" && indexMessage.email === false)
            return false;
          if (
            criteria.active !== "All" &&
            indexMessage.active !== (criteria.active === "Active")
          )
            return false;
          if (
            criteria.search &&
            !indexMessage.name
              .toLowerCase()
              .includes(criteria.search.trim().toLowerCase())
          )
            return false;
          if (
            criteria.tag &&
            +criteria.tag !== -1 &&
            !(
              indexMessage.tags?.findIndex((tag) => tag.id == criteria.tag) >= 0
            )
          )
            return false;
          if (indexMessage.deleted !== false) return false;
          return true;
        })
        .sort((objA, objB) => {
          if (criteria.sort === "updated_at")
            return objB?.updated_at?.localeCompare(objA?.updated_at);
          if (criteria.sort === "name")
            return objA.name.localeCompare(objB.name);
        });

      if (criteria.sort === "schedule") {
        let sorted_before_in_day = filteredMessages
          .filter(
            (item) =>
              item?.reference_point === "in" &&
              item?.reference_direction === "before",
          )
          .sort(
            (prev, next) =>
              Math.abs(next["send_day"]) - Math.abs(prev["send_day"]),
          );
        let sorted_now_in_day = filteredMessages
          .filter(
            (item) =>
              item?.reference_point === "in" &&
              item?.reference_direction === "",
          )
          .sort(
            (prev, next) =>
              Math.abs(next["send_day"]) - Math.abs(prev["send_day"]),
          );
        let sorted_after_in_day = filteredMessages
          .filter(
            (item) =>
              item?.reference_point === "in" &&
              item?.reference_direction === "after",
          )
          .sort(
            (prev, next) =>
              Math.abs(prev["send_day"]) - Math.abs(next["send_day"]),
          );
        let sorted_before_out_day = filteredMessages
          .filter(
            (item) =>
              item?.reference_point === "out" &&
              item?.reference_direction === "before",
          )
          .sort(
            (prev, next) =>
              Math.abs(next["send_day"]) - Math.abs(prev["send_day"]),
          );
        let sorted_now_out_day = filteredMessages
          .filter(
            (item) =>
              item?.reference_point === "out" &&
              item?.reference_direction === "",
          )
          .sort(
            (prev, next) =>
              Math.abs(next["send_day"]) - Math.abs(prev["send_day"]),
          );
        let sorted_after_out_day = filteredMessages
          .filter(
            (item) =>
              item?.reference_point === "out" &&
              item?.reference_direction === "after",
          )
          .sort(
            (prev, next) =>
              Math.abs(prev["send_day"]) - Math.abs(next["send_day"]),
          );

        const daysOrder = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ];

        let sorted_weekdays_day = filteredMessages
          .filter((item) => daysOrder.indexOf(item?.reference_point) > -1)
          .sort(
            (prev, next) =>
              daysOrder.indexOf(prev.reference_point) -
              daysOrder.indexOf(next.reference_point),
          );

        return [
          ...sorted_weekdays_day,
          ...sorted_before_in_day,
          ...sorted_now_in_day,
          ...sorted_after_in_day,
          ...sorted_before_out_day,
          ...sorted_now_out_day,
          ...sorted_after_out_day,
        ];
      }
      return filteredMessages;
    }
  }, [contextScheduler.listMessages, criteria]);

  const visibleMessage = useMemo(() => {
    let result = filteredMessages.slice(
      criteria.page * recordsPerPage,
      (criteria.page + 1) * recordsPerPage,
    );
    let flags = result?.map((d) => d.active);
    setActiveStates(flags);
    return result;
  }, [filteredMessages, criteria, recordsPerPage]);

  const handleActiveCheckBox = (position) => {
    const updatedCheckedState = activeStates.map((item, index) =>
      index === position ? !item : item,
    );
    setActiveStates(updatedCheckedState);
    contextScheduler.updateScheduledMessage({
      ...visibleMessage[position],
      active: !visibleMessage[position].active,
    });
  };

  return (
    <>
      <AddTextLine feature="Scheduler" />
      <MainBody id="schedule-a-message" title="Scheduler">
        <Flex alignment="between top">
          <div className="hms-guest-btn">
            <Button
              color="#"
              className="hms-btn hms-red-btn"
              toggleOptions="target: #scheduler-add"
              onClick={handleClickNew}
            >
              <Icon options="icon: plus; ratio: 0.7"></Icon>&nbsp;SCHEDULED
              MESSAGE
            </Button>
          </div>
        </Flex>
        <Card size="small" className="hms-form-card card-without-filters">
          <div className="uk-overflow-auto">
            <Flex alignment="between top" className="guest-form-top-filters">
              <div>
                <form
                  action=""
                  className="uk-form uk-flex uk-flex-middle hms-form"
                >
                  <div className="uk-inline">
                    <div uk-form-custom="target: > * > span:first-child">
                      <Select
                        value={criteria.type}
                        onChange={(event) =>
                          handleInputChange("type", event.target.value)
                        }
                      >
                        <SelectOption value="All">SMS & Email</SelectOption>
                        <SelectOption value="SMS">SMS</SelectOption>
                        <SelectOption value="Email">Email</SelectOption>
                      </Select>
                      <button
                        className="uk-button uk-button-default"
                        type="button"
                        tabIndex="-1"
                      >
                        <span></span>
                        <span uk-icon="icon: chevron-down"></span>
                      </button>
                    </div>
                  </div>
                  <div className="uk-inline">
                    <div uk-form-custom="target: > * > span:first-child">
                      <Select
                        name="active"
                        value={criteria.active}
                        onChange={(event) =>
                          handleInputChange("active", event.target.value)
                        }
                      >
                        <SelectOption value="All">
                          Active & Inactive Messages
                        </SelectOption>
                        <SelectOption value="Active">
                          Active Messages
                        </SelectOption>
                        <SelectOption value="Inactive">
                          Inactive Messages
                        </SelectOption>
                      </Select>
                      <button
                        className="uk-button uk-button-default hms-select-width"
                        type="button"
                        tabIndex="-1"
                      >
                        <span></span>
                        <span uk-icon="icon: chevron-down"></span>
                      </button>
                    </div>
                  </div>
                  {!(settingsContext.loading || rentalsContext.loading) && (
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select
                          value={criteria.tag}
                          onChange={(event) =>
                            handleInputChange("tag", event.target.value)
                          }
                        >
                          {[{ id: -1, name: "All Tags" }, ...tagValues].map(
                            (tag) => (
                              <SelectOption
                                key={`tags_${tag.id}`}
                                value={tag.id}
                              >
                                {tag.name}
                              </SelectOption>
                            ),
                          )}
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="uk-inline">
                    <div className="uk-search uk-search-default">
                      {criteria.search && criteria.search !== "" ? (
                        <a
                          className="uk-form-icon-flip uk-form-icon"
                          uk-icon="icon: close"
                          onClick={() => handleInputChange("search", "")}
                        ></a>
                      ) : (
                        <span
                          className="uk-search-icon-flip"
                          uk-search-icon="true"
                        />
                      )}
                      <input
                        className="uk-search-input"
                        type="search"
                        placeholder="Name"
                        value={criteria.search}
                        onChange={(event) =>
                          handleInputChange("search", event.target.value)
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div>
                <div className="hms-sortby-btn">
                  <button className="uk-button" type="button">
                    SORT BY<span uk-icon="icon: triangle-down"></span>
                  </button>
                  <div uk-dropdown="mode: click; offset: 0; animation: uk-animation-slide-top-small; duration: 400">
                    <ul className="uk-nav uk-dropdown-nav">
                      {["updated_at", "name", "schedule"].map((item, index) => (
                        <li
                          className={classnames({
                            "uk-active": criteria.sort === item,
                          })}
                          key={`sort_${index}`}
                        >
                          <Link
                            href="#"
                            onClick={() => handleInputChange("sort", item)}
                          >
                            {["Last Updated", "Name", "Schedule"][index]}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Flex>

            <table className="uk-table uk-table-hover uk-table-divider hms-table">
              <thead>
                <tr>
                  <th>Active</th>
                  <th>Type</th>
                  <th className="uk-table-expand">Name</th>
                  <th className="uk-table-expand">Scheduled For</th>
                  <th className="uk-table-expand">Tags</th>
                  <th className="uk-table-expand">Flags</th>
                  <th className="actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {contextScheduler.loading == true
                  ? TableLoading(7)
                  : visibleMessage.map((indexMessage, index) => (
                      <tr key={indexMessage.scheduled_message_id}>
                        <td>
                          <div className="autosend-checkbox">
                            <label
                              htmlFor={indexMessage.id}
                              className="setting-switch"
                            >
                              <input
                                type="checkbox"
                                id={indexMessage.id}
                                onChange={() => handleActiveCheckBox(index)}
                                checked={activeStates[index]}
                              />
                              <span className="setting-slider setting-round"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          {indexMessage.sms && (
                            <div className="hms-sms-tag">SMS</div>
                          )}
                          {indexMessage.email && (
                            <div className="hms-sms-tag">Email</div>
                          )}
                        </td>
                        <td>
                          <div className="semi-bold-font">
                            {indexMessage.name}
                          </div>
                          {indexMessage.sms &&
                            indexMessage.body?.length > 612 && (
                              <div className="property-id-msg">
                                <div>
                                  <img
                                    data-src="/images/warning.svg"
                                    data-uk-img=""
                                    loading="lazy"
                                    alt="warning"
                                    src="/images/warning.svg"
                                  />
                                  <span
                                    className="guest-missing"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    Large Text Message{" "}
                                    <img
                                      className="info-icon"
                                      src="/images/info-icon.svg"
                                      alt="info"
                                      uk-tooltip="Large Text Message. Reduce to 612 Characters"
                                      title=""
                                      aria-describedby="uk-tooltip-1745"
                                      tabindex="0"
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                        </td>
                        <td>
                          <div>
                            {Math.abs(indexMessage.send_day) === 99999
                              ? "Not Scheduled"
                              : Math.abs(indexMessage.send_day) === 99998
                                ? `Email Upon Booking at ${indexMessage.send_time}`
                                : Math.abs(indexMessage.send_day) <= 99997 &&
                                    Math.abs(indexMessage.send_day) >= 99990
                                  ? `${
                                      99997 - Math.abs(indexMessage.send_day)
                                    } Day(s) ${indexMessage.reference_direction} 
                                ${indexMessage.reference_point
                                  .toLowerCase()
                                  .replace(/\b\w/g, (s) =>
                                    s.toUpperCase(),
                                  )} at ${indexMessage.send_time}`
                                  : `${Math.abs(indexMessage.send_day)} Day(s) ${
                                      indexMessage.reference_direction
                                    } Check-${indexMessage.reference_point} at ${
                                      indexMessage.send_time
                                    }`}
                          </div>
                        </td>
                        <td className="hms-add-tag">
                          <div className="hms-guest-tags">
                            <div className="guest-tags">
                              {indexMessage.tags
                                ?.sort((a, b) => a.name.localeCompare(b.name))
                                ?.map((tag, index) => (
                                  <React.Fragment key={`tag_list_${index}`}>
                                    <div className="uk-inline">{tag.name}</div>
                                    &nbsp;
                                  </React.Fragment>
                                ))}
                            </div>
                          </div>
                        </td>
                        <td className="hms-tags">
                          {
                            (user?.details?.pms === "Streamline" ||
                            user?.details?.pms === "LiveRez" ||
                            user?.details?.pms === "Hostaway") && (
                            <>
                              {indexMessage.flags?.includes('balance_pending') && <div class="uk-inline flag-tag " uk-tooltip="Send when balance is pending">
                                Balance Pending
                              </div>}
                              {indexMessage.flags?.includes('paid_in_full') && <div class="uk-inline flag-tag" uk-tooltip="Send when paid in full">
                                Paid In Full
                              </div>}
                              {indexMessage.flags?.includes('contract_pending') && <div class="uk-inline flag-tag" uk-tooltip="Send when contact is not signed">
                                Contract Pending
                              </div>}
                              {indexMessage.flags?.includes('contract_signed') && <div class="uk-inline flag-tag" uk-tooltip="Send when contract is signed">
                                Contract Signed
                              </div>}
                              {indexMessage.lmb && <div class="uk-inline flag-tag" uk-tooltip="Send for last minute bookings">
                                Last Minute Booking
                              </div>}
                            </>
                          )}
                        </td>
                        <td className="hms-action-btn">
                          <div className="uk-flex uk-flex-top">
                            <div className="uk-inline">
                              <button
                                className="uk-button"
                                uk-toggle="target: #scheduler-add"
                                onClick={() => handleClickEdit(indexMessage)}
                                uk-tooltip="Edit"
                              >
                                <Image src="/images/edit.svg" />
                              </button>
                            </div>
                            <div className="uk-inline more-menu">
                              <button className="uk-button">
                                <Image src="/images/more.svg" />
                              </button>
                              <div
                                className="uk-navbar-dropdown"
                                data-uk-dropdown="pos: top-right"
                              >
                                <ul class="uk-nav uk-navbar-dropdown-nav">
                                  <li
                                    uk-toggle="target: #scheduler-delete"
                                  onClick={() => handleMessageClone(indexMessage)}
                                  >
                                    <a>
                                      <Image src="/images/clone.svg" /> Clone
                                    </a>
                                  </li>
                                  <li
                                    uk-toggle="target: #scheduler-share"
                                    onClick={() => handleShare(indexMessage)}
                                  >
                                    <a>
                                      <Image src="/images/share.svg" /> Share
                                    </a>
                                  </li>                                
                                  <li
                                    uk-toggle="target: #scheduler-delete"
                                    onClick={() => handleClickDelete(indexMessage)}
                                  >
                                    <a>
                                      <Image src="/images/delete.svg" /> Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>                            
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </Card>
        <Flex alignment="between top" className="guest-form-top-filters">
          <Pagination
            count={filteredMessages.length}
            index={Number(criteria.page)}
            onIndexChanged={handleIndexChanged}
          />
          <RecordsPerPage />
        </Flex>
      </MainBody>
      <SchedulerAdd />
      <SchedulerShare />
      <SchedulerDelete />
    </>
  );
};

export default withRouter(Scheduler);
