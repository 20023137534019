import http, { httpV2 } from "../http.js";

const current = () => {
  return httpV2.get("/auth/current");
};

const inbox = () => {
  return httpV2.get("/inbox");
};

const signup = async (creds) => {
  return httpV2.post("/auth/signup", creds);
};

const join = async (creds) => {
  return httpV2.post("/auth/join", creds);
};

const login = async (email, password) => {
  var fd = new FormData();
  fd.append("email", email);
  fd.append("password", password);

  return httpV2.post("/auth/login", fd);
};

const logout = () => {
  return httpV2.post("/auth/logout");
};

const reset_password = (email) => {
  return httpV2.post("/auth/password/reset", { email: email });
};

const update_password = (creds) => {
  return httpV2.post("/auth/password/update", creds);
};

const change_password = (creds) => {
  return httpV2.post("/auth/password/change", creds);
};

const reset_email = (email) => {
  return httpV2.post("/auth/email/reset", { email: email });
};

const update_email = (token, password) => {
  return httpV2.post("/auth/email/update", {
    token: token,
    password: password,
  });
};

const verify_email = (token) => {
  return httpV2.post("/auth/email/verify", { token: token });
};

const cancel_email = (token) => {
  return httpV2.post("/auth/email/cancel", { token: token });
};

const get_invitation = (token) => {
  return httpV2.get(`/auth/invitation/${token}`);
};

const update_profile_image = (attachment) => {
  return httpV2.post("/auth/user/image", { attachment: attachment });
};

const onboarding_complete = (payload) => {
  return httpV2.post("/auth/onboarding_complete", payload);
}

const AccountsService = {
  current,
  inbox,
  login,
  logout,
  signup,
  join,
  get_invitation,
  reset_password,
  update_password,
  change_password,
  verify_email,
  reset_email,
  update_email,
  cancel_email,
  update_profile_image,
  onboarding_complete,
};

export default AccountsService;
