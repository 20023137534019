import { useContext } from "react";

import { Route, Redirect } from "react-router-dom";

import { AppContexts } from "./providers";
import moment from "moment";

const AccessoryRoute = ({ component: Component, ...rest }) => {
  let authCtx = useContext(AppContexts.AuthContext);

  console.log(
    "Products:",
    !authCtx.user?.details?.product.split(",").includes("extras"),
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authCtx.user) {
          return (
            <Redirect
              to={{ pathname: "/auth/login", state: { from: props.location } }}
            />
          );
        } else if (
          authCtx.user &&
          (authCtx.user.details?.type === "expired" ||
            moment(
              ["trial", "trial-full"].includes(authCtx.user.details?.type) &&
                authCtx.user.details?.trial_end,
            ) < moment())
        ) {
          return (
            <Redirect
              to={{ pathname: "/expired", state: { from: props.location } }}
            />
          );
        } else if (
          !authCtx.user.details?.product.split(",").includes("extras")
        ) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default AccessoryRoute;
