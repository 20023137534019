const VisibilityShow = ({ restrictions }) => {
    if (!restrictions || restrictions.length === 0) {
        return <></>
    }

    const visibleAfter = restrictions.find((it) => it.restriction_type === 'visible_after');
    const visibleUntil = restrictions.find((it) => it.restriction_type === 'visible_until');

    const convertMinutesToTime = (minutes) => {
        let hours = Math.floor(minutes / 60);
        let mins = minutes % 60;
        let period = hours >= 12 ? 'PM' : 'AM';
      
        // Convert 24-hour format to 12-hour format
        hours = hours % 12 || 12; // Convert 0 to 12 for AM and 12 to 12 PM
      
        // Format minutes with leading zero if needed
        let formattedMins = mins.toString().padStart(2, '0');
      
        return `${hours}:${formattedMins} ${period}`;
    }
    const makeSentence = (restriction) => {
        let sentence = '';
        if (restriction.restriction_type === 'visible_after') sentence += 'Visible After: ';
        if (restriction.restriction_type === 'visible_until') sentence += 'Visible Until: ';

        sentence += `${Math.abs(restriction.date_offset)} Day(s) `;
        if (restriction.date_offset > 0) sentence += 'After ';
        else sentence += 'Before ';

        if (restriction.relative_point === 'check-in') sentence += 'Check-In at '
        if (restriction.relative_point === 'check-out') sentence += 'Check-Out at '

        sentence += convertMinutesToTime(restriction.time_of_day);
        return sentence;
    }
    return (
        <>
            {visibleAfter && <li>
                <input
                    type="checkbox"
                    className="uk-checkbox"
                    disabled
                    checked
                />{" "}
                <span class="availability">{makeSentence(visibleAfter)}</span>
            </li>}
            {visibleUntil && <li>
                <input
                    type="checkbox"
                    className="uk-checkbox"
                    disabled
                    checked
                />{" "}
                <span class="availability">{makeSentence(visibleUntil)}</span>
            </li>}
        </>
    );
}

export default VisibilityShow;