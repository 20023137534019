import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { AppContexts } from "../../../providers";

const TrialAccount = () => {
  const { user } = useContext(AppContexts.AuthContext);
  const location = useLocation();

  const isTrialUser =
    (user?.details.type === "trial" || user?.details.type === "trial-full") &&
    moment(user.details.trial_end) > moment();

  const hideGettingStartedLink =
    location.pathname === "/getting-started" ||
    location.pathname === "/ai-assistant";

  const oneUnitLimit =
    location.pathname === "/properties" || location.pathname === "/areas";

  return (
    <>
      {isTrialUser && (
        <div>
          <div className="uk-card uk-card-small uk-card-default hms-card trial-account-banner">
            <div className="uk-flex uk-flex-center">
              <div>
                {moment(user?.details.trial_end).diff(moment(), "days")} days
                left in your free trial.{" "}
                <u>
                  <a href="/billing">Start your subscription now.</a>
                </u>
              </div>
            </div>
          </div>
          {/* Show this link on all pages except '/getting-started' and '/ai-assistant' */}
          {oneUnitLimit && (
            <div className="uk-flex uk-flex-center subscription-required">
              Subscription is required to add more than one guide.
            </div>
          )}
          {!hideGettingStartedLink && (
            <div className="getting-started-navigation">
              <a href="/getting-started">&lt; Back to Getting Started</a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TrialAccount;
