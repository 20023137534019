import { createContext, useState } from "react";

import accountService from "../services/accounts.service";

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [inbox, setInbox] = useState(null);
  const [invitation, setInvitation] = useState(null);

  const [newUser, setNewUser] = useState({});

  const refresh = async () => {
    let [userResp, inboxResp] = await Promise.allSettled([
      accountService.current(),
      accountService.inbox(),
    ]);

    let user = userResp.value?.data;
    let inbox = inboxResp.value?.data;

    if (!user) {
      setUser(null);
      setInbox(null);
      setLoading(null);

      return;
    }

    setUser(user);
    if (!inbox) {
      setInbox(null);
    } else {
      setInbox(inbox);
    }

    setLoading(false);

    // .then((resp, err) => {
    //   setUser(resp.data);
    //   setLoading(false);

    //   return Promise.resolve(user);
    // })
    // .then(async () => {

    //   console.log(resp.data);
    //   setInbox(resp.data["inbox"])

    //   return Promise.resolve(inbox);
    // })
    // .catch((err) => {
    //   setUser(null);
    //   setInbox(null);
    //   setLoading(false);
    // });
  };

  const login = (email, password) => {
    return accountService
      .login(email, password)
      .then((resp, err) => {
        setUser(resp.data);
        if (!!resp.data.error) {
          return Promise.reject(resp.data.error);
        }
        return Promise.resolve(resp.data);
      })
      .catch((err) => {
        setUser(null);
        return Promise.reject(err);
      });
  };

  const signup = (creds) => {
    return accountService
      .signup(creds)
      .then((resp, err) => {
        setUser(resp.data);
        if (!!resp.data.error) {
          return Promise.reject(resp.data.error);
        }

        return Promise.resolve(resp.data);
      })
      .catch((err) => {
        if (!!err?.response?.data) {
          return Promise.reject(err?.response?.data);
        }
        setUser(null);
      });
  };

  const join = (creds) => {
    return accountService
      .join(creds)
      .then((resp, err) => {
        setUser(resp.data);

        console.log(resp);
        if (!!resp.data.error) {
          return Promise.reject(resp.data.error);
        }

        return Promise.resolve(resp.data);
      })
      .catch((err) => {
        if (!!err?.response?.data) {
          return Promise.reject(err?.response?.data);
        }
        setUser(null);
      });
  };

  const reset_password = (email) => {
    return accountService.reset_password(email);
  };

  const update_password = (creds) => {
    return accountService.update_password(creds);
  };

  const change_password = (creds) => {
    return accountService.change_password(creds);
  };

  const reset_email = (email) => {
    return accountService.reset_email(email);
  };

  const update_email = (token, password) => {
    return accountService.update_email(token, password);
  };

  const verify_email = (token) => {
    return accountService.verify_email(token);
  };

  const cancel_email = (token) => {
    return accountService.cancel_email(token);
  };

  const update_profile_image = async (attachment) => {
    console.log("Uploading");

    let resp = await accountService.update_profile_image(attachment);

    console.log(resp);

    let user = resp?.data;
    if (!user) {
      console.log("Error uploading profile picture:", resp);
    }

    setUser(user);
  };

  const get_invitation = (token) => {
    setLoaded(false);
    return accountService
      .get_invitation(token)
      .then((resp, err) => {
        setInvitation(resp.data);
        setLoaded(true);
        if (!!resp.error) {
          console.warn(err);
        } else {
          setInvitation(resp);
        }
      })
      .catch((err) => {
        setLoaded(true);
        console.warn(err);
      });
  };

  const logout = () => {
    return accountService
      .logout()
      .then((resp, err) => {
        if (err !== null) {
          window.location.assign("/");
        } else {
          console.warn(err);
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const onboarding_complete = (onboardingInfo) => {
    return accountService.onboarding_complete(onboardingInfo);
  }

  let authCtx = {
    user,
    inbox,
    loading,
    loaded,

    reset_password,
    update_password,
    change_password,

    get_invitation,
    invitation,

    verify_email,
    reset_email,
    update_email,
    cancel_email,

    update_profile_image,

    setUser,
    refresh,
    login,
    signup,
    join,
    logout,
    onboarding_complete,
  };

  return (
    <AuthContext.Provider value={authCtx}>{children}</AuthContext.Provider>
  );
};
