// Import base components
import http, { httpV2 } from "../http.js";

const OrdersService = {
  list: (
    page,
    per_page,
    status,
    payment_status,
    start_date,
    end_date,
    search_term,
  ) =>
    httpV2.post(`/payments/invoices`, {
      page: page,
      per_page: per_page,
      status_flag: status,
      payment_flag: payment_status,
      start_date: start_date,
      end_date: end_date,
      order_number: search_term,
    }),
  get: (order_number) => httpV2.get(`/payments/invoices/${order_number}`),
  capture: (order) => httpV2.post(`/payments/capture`, { order }),
  fulfill: (item_id) => httpV2.post(`/payments/fulfill/${item_id}`),
  refund: (refund) => httpV2.post(`/payments/refund`, { refund: refund }),
  getNewOrderCount: () => httpV2.get(`/payments/get_new_count`),
};

export default OrdersService;
