import { createContext, useState } from "react";
import diff from "diff-arrays-of-objects";
import surveysService from "../services/surveys.service";

export const SurveysContext = createContext();
export const SurveysProvider = ({ children }) => {
  const [flagIsEditing, setIsEditing] = useState(false);
  const [flagIsNew, setIsNew] = useState(false);
  const [flagIsProcessing, setIsProcessing] = useState(false);
  const [listSurveys, setSurveys] = useState([]);
  const [allSurveys, setAllSurveys] = useState([]);
  const [survey, setSurvey] = useState({});
  const [surveyResult, setSurveyResult] = useState({});
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [question, setQuestion] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [questionNo, setQuestionNo] = useState(0);

  const contextSurveys = {
    flagIsEditing,
    setIsEditing,
    flagIsNew,
    setIsNew,
    flagIsProcessing,
    loaded,
    allSurveys,
    setAllSurveys,
    listSurveys,
    setSurveys,
    survey,
    setSurvey,
    setRecordsPerPage,
    question,
    setQuestion,
    surveyResult,
    setSurveyResult,
    setPage,
    questionNo,
    setQuestionNo,
    initSurvey: () => {
      setSurvey({});
    },

    initQuestion: () => {
      setQuestion({});
    },

    upsertQuestion: async (obj) => {
      setIsProcessing(true);
      try {
        let res = await surveysService.upsertQuestion(obj);
        setIsProcessing(false);
        return res.data;
      } catch (err) {
        setIsProcessing(false);
        console.log(err);
      }
    },

    getSurveyList: () => {
      surveysService
        .getSurveyList()
        .then((res) => {
          setAllSurveys(res.data);
        })
        .catch((err) => console.log(err));
    },

    getSurveys: () => {
      setIsProcessing(true);
      surveysService
        .getSurveys(page)
        .then((res) => {
          setIsProcessing(false);
          setLoaded(true);
          setSurveys(res.data);
        })
        .catch((err) => console.log(err));
    },

    getPublicSurvey: (survey_id) =>
      surveysService
        .getPublicSurvey(survey_id)
        .then((res) => {
          if (res.data) {
            setSurvey({ ...res.data });
          }
        })
        .catch((err) => console.log(err)),

    getSurvey: (survey_id) =>
      surveysService
        .getSurveyById(survey_id)
        .then((res) => {
          if (res.data) {
            setSurvey({ ...res.data });
          }
        })
        .catch((err) => console.log(err)),

    getSurveyAndResult: (survey_id, start_date, end_date) => {
      surveysService
        .getSurveyAndResult(survey_id, start_date, end_date)
        .then((res) => {
          if (res.data) {
            setSurveyResult({ ...res.data });
          }
        })
        .catch((err) => console.log(err));
    },

    insertSurvey: async (objSurvey) => {
      setIsProcessing(true);
      try {
        let res = await surveysService.insertSurvey(objSurvey);
        if (res.data) {
          setIsProcessing(false);
          setSurveys((prev) => [...prev, res.data]);
          return res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    cloneSurvey: async (survey_id) => {
      setIsProcessing(true);
      try {
        let res = await surveysService.cloneSurvey(survey_id);
        if (res.data) {
          setIsProcessing(false);
          setSurveys((prev) => [...prev, res.data]);
          return res.data;
        }
      } catch (err) {
        setIsProcessing(false);
        console.log(err);
      }
    },

    updateSurvey: async (objSurvey) => {
      setIsProcessing(true);
      try {
        let res = await surveysService.updateSurvey(objSurvey);
        if (res.data) {
          setIsProcessing(false);
          return res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    deleteSurvey: async (objSurvey) => {
      try {
        let res = await surveysService.updateSurvey(objSurvey);
        if (res.data) {
          return res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    flagSurveyResult: async (surveyResultId) => {
      try {
        let res = await surveysService.flagSurveyResult(surveyResultId);
        if (res.data) {
          return res.data;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <SurveysContext.Provider value={contextSurveys}>
      {children}
    </SurveysContext.Provider>
  );
};
