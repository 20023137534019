import { useContext, useEffect, useMemo, useState } from "react";

import UIkit from "uikit";

import CustomBreadcrumb from "../common/CustomBreadcrumb";
import ReportsNavbar from "./Layout/navbar";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// import Payment from "./payment";
// import Subscription from "./subscription";
// import Invoices from "./invoices";
// import Account from "./account";

import { AppContexts } from "../../providers";

import Loading from "../../components/common/Loading/index.js";

import { CheckoutForm } from "./subscriptions/checkout-form.js";
import { ModifyForm } from "./subscriptions/modify-form.js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Billing = () => {
  const {
    loading,
    customerUrl,
    getCustomerUrl,
    accountCustomer,
    accountSubscription,
    getAccountSubscription,
  } = useContext(AppContexts.StripeContext);

  const {
    loading: rentalsLoading,
    rentals,
    get,
  } = useContext(AppContexts.RentalsContext);

  useEffect(() => {
    document.title = "Billing - RueBaRue";

    get();
    getCustomerUrl();
    getAccountSubscription();

    let { status, action, error } = {
      ...Object.fromEntries(new URLSearchParams(window.location.search)),
    };

    if (status === "success") {
      if (action === "subscription") {
        UIkit.notification("Your subscription is now active");
      } else if (action === "modify") {
        UIkit.notification("Your payment method has been updated");
      }
    } else if (!!error) {
      UIkit.notification(`Error: ${error}`, "error");
    }
  }, []);

  const options = useMemo(() => {
    return {
      mode: accountSubscription ? "setup" : "subscription",
      amount: 0,
      currency: "usd",
    };
  }, [accountSubscription]);

  return (
    <section id="team-schedule-template">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">Billing</h1>
      </div>
      <div id="hms-main-body">
        <div className="billing">
          <div className="billing-button">
            {!loading && customerUrl && (
              <div>
                <p>
                  RueBaRue uses Stripe to manage billing. Go to the Billing
                  Portal to view your current subscription plan and invoice
                  history, and add or update your credit card information.
                </p>
                <a href={customerUrl} target="_blank">
                  <button className="uk-button hms-btn hms-red-btn">
                    Open Billing Portal
                  </button>
                </a>

                {!!accountSubscription && (
                  <div>
                    <div className="subscription-info">
                      <h3>Current Subscription</h3>

                      <label>Product</label>
                      <div>{accountSubscription?.product?.description}</div>

                      <label>Price</label>
                      <div>
                        {(
                          (accountSubscription?.price?.unit_amount *
                            accountSubscription.quantity) /
                          100
                        ).toFixed(2)}
                      </div>

                      <label>Payment Methods</label>
                      <ul className="uk-list payment-methods">
                        {(accountSubscription?.payment_methods || []).map(
                          (method) => {
                            let { card } = method;

                            return (
                              <li>{`${card.display_brand} ${card.last4} - ${card.exp_month}/${card.exp_year.toString().substr(2)}`}</li>
                            );
                          },
                        )}
                      </ul>
                    </div>

                    {/*
                    <h3>Update your payment method</h3>

                    <Elements stripe={stripePromise} options={options}>
                      <ModifyForm />
                    </Elements>
                    */}
                  </div>
                )}
              </div>
            )}

            {!loading && !customerUrl && (
              <div className="setting-tab-content uk-active">
                {loading || rentalsLoading ? (
                  <Loading />
                ) : !loading && !accountSubscription ? (
                  <div className="uk-card mx-w-665">
                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm rentals={rentals} />
                    </Elements>
                  </div>
                ) : (
                  <div className="current-subscription">
                    <div className="subscription-info">
                      <h3>Current Subscription</h3>

                      <p>
                        Product: <b>{accountSubscription?.product?.description}</b><br/>
                        Price: 
                        <b>
                          {(
                            (accountSubscription?.price?.unit_amount *
                              accountSubscription.quantity) /
                            100
                          ).toFixed(2)}
                        </b>
                      </p>
                      Payment Method(s): <b>
                      <ul className="uk-list payment-methods">
                        {(accountSubscription?.payment_methods || []).map(
                          (method) => {
                            let { card } = method;

                            return (
                              <li>{`${card.display_brand} ${card.last4} - ${card.exp_month}/${card.exp_year.toString().substr(2)}`}</li>
                            );
                          },
                        )}
                      </ul>
                      </b>
                    </div>

                    <Elements stripe={stripePromise} options={options}>
                      <ModifyForm />
                    </Elements>

                    <p>
                      To cancel your subscription please email{" "}
                      <a href="mailto:sales@ruebarue.com">sales@ruebarue.com</a>
                    </p>
                  </div>
                )}
              </div>
            )}

            {!!loading && <div data-uk-spinner="ratio: 0.5;"></div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Billing;

// import { useContext, useEffect, useMemo, useState } from "react";

// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import UIkit from "uikit";

// import Loading from "../../../components/common/Loading/index.js";
// import { AppContexts } from "../../../providers";
// import { CheckoutForm } from "./checkout-form";
// import { ModifyForm } from "./modify-form";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// const Subscription = () => {
//   const {
//     loading,
//     accountCustomer,
//     accountSubscription,
//     getAccountSubscription,
//   } = useContext(AppContexts.StripeContext);

//   const {
//     loading: rentalsLoading,
//     rentals,
//     get,
//   } = useContext(AppContexts.RentalsContext);

//   const options = useMemo(() => {
//     return {
//       mode: accountSubscription ? "setup" : "subscription",
//       amount: 0,
//       currency: "usd",
//     };
//   }, [accountSubscription]);

//   useEffect(() => {
//     get();
//     getAccountSubscription();

//     let { status, action, error } = {
//       ...Object.fromEntries(new URLSearchParams(window.location.search)),
//     };

//     if (status === "success") {
//       if (action === "subscription") {
//         UIkit.notification("Your subscription is now active");
//       } else if (action === "modify") {
//         UIkit.notification("Your payment method has been updated");
//       }
//     } else if (!!error) {
//       UIkit.notification(`Error: ${error}`, "error");
//     }
//   }, []);

//   return (
//     <li className="setting-tab-content uk-active">
//       {loading || rentalsLoading ? (
//         <Loading />
//       ) : !loading && !accountSubscription ? (
//         <div className="uk-card hms-left-tabs-content-card mx-w-665">
//           <Elements stripe={stripePromise} options={options}>
//             <CheckoutForm rentals={rentals} />
//           </Elements>
//         </div>
//       ) : (
//         <div className="uk-card hms-left-tabs-content-card mx-w-665 current-subscription">
//           <div className="subscription-info">
//             <h3>Current subscription</h3>

//             <label>Product</label>
//             <div>{accountSubscription?.product?.description}</div>

//             <label>Price</label>
//             <div>
//               {(
//                 (accountSubscription?.price?.unit_amount *
//                   accountSubscription.quantity) /
//                 100
//               ).toFixed(2)}
//             </div>

//             <label>Payment Methods</label>
//             <ul className="uk-list payment-methods">
//               {(accountSubscription?.payment_methods || []).map((method) => {
//                 let { card } = method;

//                 return (
//                   <li>{`${card.display_brand} ${card.last4} - ${card.exp_month}/${card.exp_year.toString().substr(2)}`}</li>
//                 );
//               })}
//             </ul>
//           </div>

//           <Elements stripe={stripePromise} options={options}>
//             <ModifyForm />
//           </Elements>

//           <p>
//             To cancel your subscription please email{" "}
//             <a href="mailto:sales@ruebarue.com">sales@ruebarue.com</a>
//           </p>
//         </div>
//       )}
//     </li>
//   );
// };

// export default Subscription;
