import { httpV2 } from "../http.js";

const StripeService = {
  getCustomerUrl: () => httpV2.post("stripe/customer_url"),
  checkAccount: () => httpV2.post("stripe/account"),
  createAccount: () => httpV2.post("stripe/create_account"),
  linkAccount: (account) => httpV2.post("stripe/link_account", account),
  capture: (order) => httpV2.post(`stripe/capture_intent`, { order }),
  cancel: (order) => httpV2.post(`stripe/cancel_intent`, { order }),
  refund: (refund) => httpV2.post(`stripe/refund_intent`, { refund }),

  getAccountSubscription: () => httpV2.get(`stripe/get_account_subscription`),
  createSetupIntent: () => httpV2.post(`stripe/create_setup_intent`),
  createAccountCustomer: (customer) =>
    httpV2.post(`stripe/create_account_customer`, customer),
  createAccountSubscription: (subscription) =>
    httpV2.post(`stripe/create_account_subscription`, subscription),
};

export default StripeService;
